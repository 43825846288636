import React, { FC, useContext, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { OrderNode, StatusChoice, OrderNodeConnection } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { StyledButton, LoadingSpinnerIonRow, StyledTable } from './Table.styles';
import { MaxWidthText } from '@src/components/Desktop/Containers/MaxWidthText';
import { IonCol, IonIcon, IonSpinner, IonTitle } from '@ionic/react';
import { Tag } from '@src/components/Elements/Tag';
import { ellipsisVertical, add, funnel } from 'ionicons/icons';
import { Pagination } from '@src/components/Desktop/Elements';
import { Alert, Popover } from 'antd';
import { PopoverRow } from '../PopoverRow';
import { PopoverTitle } from '../PopoverTitle';
import { TagType } from '@src/components/Elements/Tag/Tag';
import {
  StyledTableHeaderActions,
  StyledTableHeaderButton,
} from '@src/components/Desktop/Containers/TableHeaderActions';
import { DrawerButton } from '@src/components/Elements/DrawerButton';
import { FilterForm } from '../FilterForm/FilterForm';
import { ModalEnum } from '../List';
import { TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Order } from '@src/graphql/Order';
import { ColumnProps } from 'antd/lib/table/Column';
import { SortSettings } from '@src/components/Desktop/Pages/Private/Piles/List/Table/Table';
import { OrderStatus } from '@src/graphql/Order/Types';
import { addRowClickHandler } from '@src/helpers/Table';
import Moment from '@src/global/Moment';

const allowedOrderStatusChanges = {
  [StatusChoice.Created]: [StatusChoice.Allocated],
};

interface TableProps extends AntdTableProps<any> {
  itemsPerPage: number;
  onItemClick?: (event: React.MouseEvent, row: OrderNode, rowIndex: number) => void;
  selectedRows: OrderNode[];
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  setSelectedRows: Dispatch<SetStateAction<OrderNode[]>>;
  setShowPopover: Dispatch<SetStateAction<string>>;
  showPopover: string;
  toggleModal: (modalType: ModalEnum, order?: OrderNode) => void;
}

export interface MaxSizeProps {
  maxWidth?: string;
}

export const Table: FC<TableProps> = ({
  itemsPerPage,
  selectedRows,
  setItemsPerPage,
  setSelectedRows,
  onItemClick,
  toggleModal,
  showPopover,
  setShowPopover,
  ...props
}) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [sort, setSort] = useState<SortSettings | undefined>();
  const [queryVariables, setQueryVariables] = useState<any>({
    first: itemsPerPage,
  });

  const [page, setPage] = useState<number>(1);
  const [getOrders, { loading, error, data, refetch }] = useLazyQuery<{ orders: OrderNodeConnection }>(
    Order.getPaginatedOrders,
    {
      variables: {
        activeOnly: true,
        isPlausible: false,
        ...queryVariables,
      },
      fetchPolicy: 'network-only',
      onError: () => {
        setToast({
          type: TOAST_TYPES.ERROR,
          message: t('desktop.pages.orders.list.error.message'),
          description: t('desktop.pages.orders.list.error.description'),
        });
      },
    },
  );

  useEffect(() => {
    setQueryVariables((currentQueryVariables) => ({ ...currentQueryVariables, first: itemsPerPage }));
    setPage(1);
  }, [itemsPerPage, setPage, setQueryVariables]);

  useEffect(() => {
    if (!sort) {
      setQueryVariables((previous) => ({ ...previous, orderBy: undefined }));
    } else {
      const sortIndicator = sort?.sortOrder === 'descend' ? '-' : '';

      setQueryVariables((previous) => ({ ...previous, orderBy: `${sortIndicator}${sort?.field}` }));
    }
  }, [sort, setQueryVariables]);

  const onSortChange = (col, key: string | null = null) => {
    const cleanColKey = key || col.key.split('.')[0];

    setSort((previous) => {
      if (!previous?.field || previous?.field === cleanColKey) {
        switch (previous?.sortOrder) {
          case 'ascend':
            return { ...previous, sortOrder: 'descend' };
          case 'descend':
            return undefined;
          default:
            return { field: cleanColKey, sortOrder: 'ascend' };
        }
      }

      return { field: cleanColKey, sortOrder: 'ascend' };
    });
  };

  useEffect(() => {
    getOrders();
  }, [queryVariables, getOrders]);

  const { orders: { edges } = { edges: [] as any } } = data || {};
  const orders = edges.map(({ node }) => node);

  const handlePopoverVisibleChange = (record) => {
    if (showPopover === record.id) {
      setShowPopover('');
    } else if (record.id !== 'title') {
      setSelectedRows([record]);
      setShowPopover(record);
    } else if (selectedRows.length > 0) {
      setShowPopover(record);
    }
  };

  const onCellClicked = (e: React.MouseEvent, record: OrderNode, index: number | undefined) => {
    if (onItemClick && index !== undefined) {
      onItemClick(e, record, index);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRows.map((row) => row.id),
    onChange: (selectedRowKeys, rows) => {
      setSelectedRows(rows);
    },
  };

  const [setOrderStatus] = useMutation(Order.setOrderStatus);
  const handleOrderStatusChange = async (orderIds: string[], targetStatus: StatusChoice) => {
    try {
      // Additional frontend validation so save request
      orderIds.forEach((orderId) => {
        const order = orders.find((_order) => _order.id === orderId);
        if (!order) throw Error();
        const statusRules = allowedOrderStatusChanges[order.status];
        if (!statusRules || !statusRules.includes(targetStatus)) {
          throw Error();
        }
      });
      await setOrderStatus({ variables: { status: targetStatus, idList: orderIds } });
      refetch();
      setToast({
        type: TOAST_TYPES.SUCCESS,
        message: t('desktop.pages.orders.list.success.allocated'),
        description: t('desktop.pages.orders.list.success.allocatedDescription'),
      });
    } catch (error) {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.orders.list.error.statusChange'),
        description: t('desktop.pages.orders.list.error.allocation'),
      });
    }
  };

  useEffect(() => {
    const el = document;
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    el.dispatchEvent(event);
  });

  let columns: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.orders.list.columns.number'),
      dataIndex: 'number',
      sorter: true,
      sortOrder: sort?.field === 'number' ? sort?.sortOrder : null,
      onHeaderCell: (col) => ({
        onClick: () => onSortChange(col),
      }),
    },
    {
      title: t('desktop.pages.orders.list.columns.customer'),
      dataIndex: ['customer', 'name'],
      render: (text) => <MaxWidthText maxWidth='20em'>{text}</MaxWidthText>,
    },
    {
      title: t('desktop.pages.orders.list.columns.supplier'),
      dataIndex: ['supplier', 'name'],
      render: (text) => <MaxWidthText maxWidth='20em'>{text}</MaxWidthText>,
    },
    {
      title: t('desktop.pages.orders.list.columns.status'),
      dataIndex: 'status',
      render: (text) => (
        <Tag
          type={text === OrderStatus.Created ? TagType.PRIMARY : TagType.DEFAULT}
          key={text}
          value={text}
          text={t(`general.status.${text}`)}
        />
      ),
      sorter: true,
      sortOrder: sort?.field === 'orderedStatus' ? sort?.sortOrder : null,
      onHeaderCell: (col) => ({
        // use orderedStatus as a key instead of status because the backend offers it to sort the orders in by the
        // order of status
        onClick: () => onSortChange(col, 'orderedStatus'),
      }),
    },
    {
      title: t('desktop.pages.orders.list.columns.note'),
      dataIndex: 'note',
      render: (text) => <MaxWidthText>{text ? text : '-'}</MaxWidthText>,
    },
    {
      title: t('desktop.pages.orders.list.columns.driver'),
      dataIndex: ['driver', 'firstName'],
      render: (text, record: any) => (
        <span>
          {text} {record.driver && record.driver.lastName}
        </span>
      ),
      sorter: true,
      sortOrder: sort?.field === 'driver__lastName' ? sort?.sortOrder : null,
      onHeaderCell: (col) => ({
        onClick: () => onSortChange(col, 'driver__lastName'),
      }),
    },
    {
      title: t('desktop.pages.orders.list.columns.planDate'),
      dataIndex: 'planDate',
      render: (text) => text && <Moment format='DD.MM.YY'>{text}</Moment>,
      sorter: true,
      sortOrder: sort?.field === 'planDate' ? sort?.sortOrder : null,
      onHeaderCell: (col) => ({
        onClick: () => onSortChange(col),
      }),
    },
    {
      title: t('desktop.pages.orders.list.columns.repeatEndDate'),
      dataIndex: 'repeatEndDate',
      render: (text) => (text ? <Moment format='DD.MM.YY'>{text}</Moment> : '-'),
    },
    {
      title: t('desktop.pages.orders.list.columns.finishedAt'),
      dataIndex: 'finishedAt',
      render: (text) => (text ? <Moment format='DD.MM.YY'>{text}</Moment> : '-'),
      sorter: true,
      sortOrder: sort?.field === 'finishedAt' ? sort?.sortOrder : null,
      onHeaderCell: (col) => ({
        onClick: () => onSortChange(col),
      }),
    },
    {
      title: t('desktop.pages.orders.list.columns.vehicle'),
      dataIndex: ['vehicle', 'registrationNumber'],
    },
    {
      title: () =>
        selectedRows.length > 0 ? (
          <Popover
            placement='leftTop'
            content={<PopoverTitle handleOrderStatusChange={handleOrderStatusChange} orders={selectedRows} />}
            onOpenChange={() => handlePopoverVisibleChange({ id: 'title' })}
            trigger='click'
            overlayClassName={'popover-actions'}
          >
            <span>
              <IonIcon mode='md' icon={ellipsisVertical} size='small' />
            </span>
          </Popover>
        ) : (
          <IonIcon mode='md' icon={ellipsisVertical} size='small' color='medium' />
        ),
      dataIndex: 'action',
      fixed: 'right',
      width: 50,
      render: (text, record: any) => (
        <Popover
          placement='leftTop'
          content={
            <PopoverRow handleOrderStatusChange={handleOrderStatusChange} toggleModal={toggleModal} order={record} />
          }
          onOpenChange={() => handlePopoverVisibleChange(record)}
          trigger='click'
          overlayClassName={'popover-actions'}
        >
          <span>
            <IonIcon mode='md' icon={ellipsisVertical} size='small' />
          </span>
        </Popover>
      ),
    },
  ];

  columns = addRowClickHandler(columns, onCellClicked);

  return (
    <>
      {error && (
        <Alert
          message={t('desktop.pages.orders.list.error.message')}
          description={t('desktop.pages.orders.list.error.description')}
          type='error'
          showIcon
        />
      )}
      {loading && (
        <LoadingSpinnerIonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </LoadingSpinnerIonRow>
      )}
      <StyledTableHeaderActions>
        <StyledTableHeaderButton size='large' type='link' onClick={() => toggleModal(ModalEnum.ADD)}>
          <IonIcon icon={add} />
          {t('desktop.pages.orders.list.createOrder')}
        </StyledTableHeaderButton>
        <DrawerButton icon={funnel} buttonText={t('desktop.pages.general.filter.button')}>
          <FilterForm
            setQueryVariables={setQueryVariables}
            setSelectedRows={setSelectedRows}
            setPage={setPage}
            itemsPerPage={itemsPerPage}
          />
        </DrawerButton>
      </StyledTableHeaderActions>
      {data &&
        (edges && edges.length > 0 ? (
          <>
            <StyledTable
              {...props}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={orders}
              rowKey='id'
              key={itemsPerPage}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            {!error && (
              <Pagination
                loading={loading}
                pageInfo={data.orders && data.orders.pageInfo}
                page={page}
                setPage={setPage}
                queryVariables={queryVariables}
                setQueryVariables={setQueryVariables}
              />
            )}
            {error && (
              // uses callback wrapper because of this issue ->
              // https://github.com/apollographql/apollo-client/issues/1291#issuecomment-367911441
              // eslint-disable-next-line
              <StyledButton onClick={() => refetch()}>
                {t('desktop.pages.orders.list.error.buttonRefetch')}
              </StyledButton>
            )}
          </>
        ) : (
          <IonTitle className='ion-text-center'>{t('desktop.pages.orders.list.noOrdersAvailable')}</IonTitle>
        ))}
    </>
  );
};
