import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Wrapper } from '@src/components/Elements';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { PileNodeConnection, PlotNode } from '@src/generated/schema';
import { IonRow, IonCol, IonSpinner, IonIcon } from '@ionic/react';
import {
  BROACHED_PILE_CELL_CLASS,
  StyledPlotDetailTable,
} from '@src/components/Desktop/Pages/Private/Plots/Show/Show.styles';
import { Pile } from '@src/graphql/Pile';
import { checkmark } from 'ionicons/icons';
import { Tag, TagType } from '@src/components/Elements/Tag';
import { PileStatus } from '@src/graphql/Pile/Types';
import { ColumnProps } from 'antd/es/table';

export interface ShowProps {
  plot?: PlotNode;
}

export const Show: FC<ShowProps> = ({ plot }) => {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<{
    piles: PileNodeConnection;
  }>(Pile.getPaginatedPiles, {
    variables: { plotId: plot && plot.id },
    fetchPolicy: 'network-only',
  });
  const { piles: { edges: pileEdges } = { edges: [] as any } } = data || {};
  const piles = pileEdges.map(pileEdge => pileEdge.node);

  const getTagType = (text: PileStatus) => {
    if (text === PileStatus.Disposable || text === PileStatus.InRemoval) return TagType.PRIMARY;
    if (text === PileStatus.Locked) return TagType.ERROR;

    return TagType.DEFAULT;
  };

  const pilesSummary: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.number'),
      dataIndex: 'number',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: text,
      }),
    },
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.location'),
      dataIndex: 'location',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: text,
      }),
    },
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.status.title'),
      dataIndex: 'status',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: (
          <Tag
            type={getTagType(text)}
            key={text}
            value={text}
            text={t(`desktop.pages.plots.show.pilesSummary.columns.status.${text}`)}
          />
        ),
      }),
    },
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.sort'),
      dataIndex: 'sort',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: text,
      }),
    },
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.rest'),
      dataIndex: 'rest',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: text,
      }),
    },
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.cubikActual'),
      dataIndex: 'cubikActual',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: text,
      }),
    },
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.cubikSolidActual'),
      dataIndex: 'cubikSolidActual',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: text,
      }),
    },
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.cbkNet'),
      dataIndex: 'cbkNet',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: text,
      }),
    },
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.cbkSolid'),
      dataIndex: 'cbkSolid',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: text,
      }),
    },
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.count'),
      dataIndex: 'count',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: text,
      }),
    },
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.countActual'),
      dataIndex: 'countActual',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: text,
      }),
    },
    {
      title: t('desktop.pages.plots.show.pilesSummary.columns.isBroached'),
      dataIndex: 'isBroached',
      onCell: ({ isBroached }) => ({
        className: isBroached ? BROACHED_PILE_CELL_CLASS : undefined,
      }),
      render: (text, { isBroached }) => ({
        children: <>{!!isBroached && <IonIcon mode='md' icon={checkmark} size='small' />}</>,
      }),
    },
  ];

  return (
    <div>
      <Wrapper>
        {loading && (
          <IonRow>
            <IonCol className='ion-text-center'>
              <IonSpinner name='crescent' />
            </IonCol>
          </IonRow>
        )}

        {data && (
          <>
            <StyledPlotDetailTable
              title={() => t('desktop.pages.plots.show.pilesSummary.title')}
              columns={pilesSummary}
              dataSource={piles}
              rowKey='id'
              pagination={false}
            />
          </>
        )}

        {error && (
          <Alert
            message={t('desktop.pages.plots.show.overview.error.message')}
            description={t('desktop.pages.plots.show.overview.error.description')}
            type='error'
            showIcon
          />
        )}
      </Wrapper>
    </div>
  );
};
