import React, { FC } from 'react';
import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReadOnlyInventorySummaryOrderNode } from '../Show';
import Moment from '@src/global/Moment';

export interface DetailProps {
  order: ReadOnlyInventorySummaryOrderNode;
}

export const Detail: FC<DetailProps> = ({ order }) => {
  const { t } = useTranslation();

  return (
    <>
      {order && (
        <Descriptions>
          <Descriptions.Item label={t('desktop.pages.orders.list.columns.number')}>{order.number}</Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.list.columns.createdBy')}>
            {order.createdBy ? `${order.createdBy.firstName} ${order.createdBy.lastName}` : ''}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.list.columns.createdAt')}>
            {order.createdAt && <Moment format='DD.MM.YYYY'>{order.createdAt}</Moment>}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.list.columns.lastModifiedAt')}>
            {order.lastmodifiedAt && <Moment format='DD.MM.YYYY'>{order.lastmodifiedAt}</Moment>}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.list.columns.lastModifiedBy')}>
            {order.lastmodifiedBy ? `${order.lastmodifiedBy.firstName} ${order.lastmodifiedBy.lastName}` : '-'}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.list.columns.supplier')}>
            {order.supplier && order.supplier.name}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.list.columns.customer')}>
            {order.customer.name}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.list.columns.status')}>
            {t(`desktop.pages.orders.show.values.status.${order.status}`)}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.show.labels.amount')}>{order.amount}</Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.list.columns.driver')}>
            {order.driver && order.driver.firstName} {order.driver && order.driver.lastName}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.list.columns.vehicle')}>
            {order.vehicle && order.vehicle.registrationNumber}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.list.columns.planDate')}>
            <Moment format='DD.MM.YYYY'>{order.planDate}</Moment>
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.show.labels.transportMedia')}>
            {order.transportMedia && order.transportMedia.s3Path && (
              <a href={order.transportMedia && order.transportMedia.s3Path} download target='_self'>
                {t('desktop.pages.orders.show.values.transportMedia')}
              </a>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.show.labels.repeat')}>
            {order.repeat
              ? t('desktop.pages.orders.show.values.repeat.true')
              : t('desktop.pages.orders.show.values.repeat.false')}
          </Descriptions.Item>
          <Descriptions.Item label={t('general.repeatEndDate')}>
            {order.repeatEndDate ? <Moment format='DD.MM.YYYY'>{order.repeatEndDate}</Moment> : '-'}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.show.labels.deliveryDate')}>
            {order.finishedAt ? <Moment format='DD.MM.YYYY'>{order.finishedAt}</Moment> : '-'}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.show.labels.deliveryType')}>
            {t(`desktop.pages.orders.show.values.deliveryType.${order.deliveryType}`)}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.show.labels.deliveryNoteMedia')}>
            {order.deliveryNoteCustomer?.s3Path && (
              <a href={order.deliveryNoteCustomer?.s3Path} download target='_self'>
                {t('desktop.pages.orders.show.values.transportMedia')}
              </a>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.show.labels.deliveryNoteSupplier')}>
            {order.deliveryNoteSupplier?.s3Path && (
              <a href={order.deliveryNoteSupplier?.s3Path} download target='_self'>
                {t('desktop.pages.orders.show.values.transportMedia')}
              </a>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.show.labels.type')}>
            {t(`desktop.pages.orders.show.values.type.${order.type}`)}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.show.labels.note')}>{order.note}</Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.show.labels.noteDriver')}>
            {order.noteDriver}
          </Descriptions.Item>
          <Descriptions.Item label={t('desktop.pages.orders.show.labels.isPlausible')}>
            {order.isPlausible
              ? t('desktop.pages.orders.show.values.isPlausible.true')
              : t('desktop.pages.orders.show.values.isPlausible.false')}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};
