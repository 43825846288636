import { transparentize } from 'polished';

interface BorderRadius {
  default: number;
  none: number;
  rounded: number;
}

/* eslint-disable  @typescript-eslint/member-ordering */
interface Breakpoints {
  xsmall: number | string;
  small: number | string;
  medium: number | string;
  large: number | string;
  xlarge: number | string;
  xxlarge: number | string;
}
/* eslint-enable  @typescript-eslint/member-ordering */

interface Colors {
  alto: string;
  black: string;
  cameo: string;
  cinnabar: string;
  codGray: string;
  doveGray: string;
  emerald: string;
  gallery: string;
  mercury: string;
  pictonBlue: string;
  silverChalice: string;
  tundora: string;
  twine: string;
  white: string;
}

interface FontFamilies {
  primary: string;
  secondary: string;
}

interface FontWeights {
  primary: { bold: string };
  secondary: { regular: string };
}

interface Globals {
  borderRadius: number;
  borderWidth: number;
  brandColor: string;
  brandShadow: string;
  color: string;
  fontFamily: string;
  fontSize: number;
}

interface Mixins {
  boxShadow: (color: string) => string;
}

interface Theme {
  borderRadius: BorderRadius;
  color: Colors;
  fontFamily: FontFamilies;
  fontWeight: FontWeights;
  global: Globals;
  media: Breakpoints;
  mixins: Mixins;
}

const COLORS: Colors = {
  black: '#000000',
  cinnabar: '#e55039',
  doveGray: '#646464',
  gallery: '#efefef',
  tundora: '#4a4a4a',
  twine: '#bf8c60',
  white: '#ffffff',
  emerald: '#65cb7b',
  silverChalice: '#a0a0a0',
  mercury: '#e7e7e7',
  alto: '#dfdfdf',
  cameo: '#D9B391',
  codGray: '#0c0c0c',
  pictonBlue: '#2a93ee',
};

// Sizes will be transformed into pixel and percentage values via styled-components.
const BORDER_RADIUS: BorderRadius = {
  none: 0,
  rounded: 50,
  default: 2,
};

// Sizes will be transformed into pixel values via styled-components.
export const BREAKPOINTS: Breakpoints = {
  xsmall: 0,
  small: 576,
  medium: 768,
  large: 960,
  xlarge: 1200,
  xxlarge: 1440,
};

const MEDIA_QUERIES: Breakpoints = {
  xsmall: `(min-width: ${BREAKPOINTS.xsmall}px)`,
  small: `(min-width: ${BREAKPOINTS.small}px)`,
  medium: `(min-width: ${BREAKPOINTS.medium}px)`,
  large: `(min-width: ${BREAKPOINTS.large}px)`,
  xlarge: `(min-width: ${BREAKPOINTS.xlarge}px)`,
  xxlarge: `(min-width: ${BREAKPOINTS.xxlarge}px)`,
};

const MIXINS: Mixins = {
  boxShadow: (color) => `0 5px 8px 0 ${transparentize(0.7, color)}`, // eslint-disable-line  no-magic-numbers
};

const FONT_FAMILIES: FontFamilies = {
  primary: 'Vollkorn',
  secondary: 'PT Sans',
};

// Take those values right from the @font-face declarations.
const FONT_WEIGHTS: FontWeights = {
  primary: { bold: 'bold' },
  secondary: { regular: 'normal' },
};

const GLOBALS: Globals = {
  borderRadius: BORDER_RADIUS.default,
  borderWidth: 1,
  brandColor: COLORS.twine,
  brandShadow: MIXINS.boxShadow(COLORS.black),
  color: COLORS.doveGray,
  fontFamily: FONT_FAMILIES.secondary,
  fontSize: 15, // px value
};

export const THEME: Theme = {
  borderRadius: BORDER_RADIUS,
  color: COLORS,
  fontFamily: FONT_FAMILIES,
  fontWeight: FONT_WEIGHTS,
  global: GLOBALS,
  media: MEDIA_QUERIES,
  mixins: MIXINS,
};
