import React, { FC, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { Wrapper } from '@src/components/Elements';
import { TestIds } from '@src/global';
import { Order } from '@src/graphql/Order';
import { Alert, Button, Popover, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledOrderDetailTable } from './Show.styles';
import {
  InventoryNodeConnection,
  OrderNode,
  TimeNodeConnection,
  PileNode,
  InventoryNode,
  InventorySummaryNode,
  StatusChangeNode,
  RouteNodeInput,
} from '@src/generated/schema/index';
import { Tag } from '@src/components/Elements/Tag';
import { TagType } from '@src/components/Elements/Tag/Tag';
import { IonRow, IonCol, IonSpinner, IonIcon } from '@ionic/react';
import { Detail } from './Detail';
import { ColumnProps } from 'antd/lib/table/Column';
import { ConfirmModal, Modal } from '@src/components/Elements/Modal';
import { MeContext, Permissions, TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { ApolloError } from 'apollo-client';
import { InventoryEdit } from '@src/components/Desktop/Pages/Private/Orders/Show/InventoryEdit';
import { PileStatus } from '@src/graphql/Pile/Types';
import { addRowClickHandler } from '@src/helpers/Table';
import { ellipsisVertical, trashBinOutline } from 'ionicons/icons';
import { PopoverTitle } from '@src/components/Desktop/Pages/Private/Orders/Show/PopoverTitle';
import { StatusChangesList } from '@src/components/Desktop/Pages/Private/Orders/Show/StatusChangesList';
import { closePopups } from '@src/helpers/DomManipulations';
import { WagonInventoryForm } from '@src/components/Containers/InventoryForm';
import { StyledTableHeaderButton } from '@src/components/Desktop/Containers/TableHeaderActions';
import { Inventory } from '@src/graphql/Inventory';
import { APIError } from '@src/components/Elements/Wrapper/Form';
import Moment from '@src/global/Moment';

export enum InventoryModalEnum {
  INVENTORY_EDIT = 'editInventory',
  INVENTORY_CREATE = 'createInventory',
  INVENTORY_DELETE = 'deleteInventory',
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const InventoryDeleteConfirm: React.FC<{ errors: ApolloError | undefined }> = ({ errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography>{t('desktop.pages.orders.show.inventories.wagonInventories.delete.areYouSure')}</Typography>
      {errors && <APIError errors={errors} />}
    </>
  );
};

export interface ShowProps {
  order: OrderNode | undefined;
  piles: PileNode[];
  setPiles: (NewState: PileNode[]) => void;
  setRoute: (NewState: RouteNodeInput[]) => void;
  toggleModal: () => void;
}

export interface ReadOnlyInventorySummaryOrderNode extends Omit<OrderNode, 'statusChanges' | 'inventorySummary'> {
  inventorySummary?: ReadonlyArray<InventorySummaryNode>;
  statusChanges?: ReadonlyArray<StatusChangeNode>;
}

export interface OrderDetailResponse {
  inventories: InventoryNodeConnection;
  order: ReadOnlyInventorySummaryOrderNode;
  times: TimeNodeConnection;
}

export const Show: FC<ShowProps> = ({ order, setPiles, setRoute, piles, toggleModal }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const { userHasPermissions, userHasPermission } = useContext(MeContext);
  const [data, setData] = useState<OrderDetailResponse | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [showPopover, setShowPopover] = useState<string>('');
  const [selectedPileRows, setSelectedPileRows] = useState<PileNode[]>([]);
  const [deleteInventoryFun, { loading: deleteInventoryLoading, error: deleteInventoryError }] = useMutation(
    Inventory.deleteInventory,
    {
      refetchQueries: ['order'],
    },
  );

  // initial data
  const {
    loading: initialLoading,
    error: initialError,
    data: initialData,
  } = useQuery<{
    inventories: any;
    order: any;
    times: TimeNodeConnection;
  }>(Order.getOrderDetail, {
    variables: { orderId: order && order.id },
    fetchPolicy: 'network-only',
  });

  // for updating the data
  const [getOrderDetail, { loading: reFetchLoading, error: reFetchError, data: reFetchData }] = useLazyQuery<{
    inventories: any;
    order: any;
    times: TimeNodeConnection;
  }>(Order.getOrderDetail, {
    variables: { orderId: order && order.id },
    fetchPolicy: 'network-only',
  });

  const [showModal, setShowModal] = useState<string>('');
  const [selectedInventory, setSelectedInventory] = useState<InventoryNode | undefined>(undefined);
  const { inventories: { edges: edgesInventories } = { edges: [] as any } } = data || {};
  const inventories = edgesInventories.map(({ node }) => node).filter((item) => !item.wagonNumber && item.isCalculated);
  const wagonInventories = edgesInventories.map(({ node }) => node).filter((item) => !!item.wagonNumber);
  const inventorySummary = data?.order?.inventorySummary || [];

  const { times: { edges: edgesTimes } = { edges: [] as any } } = data || {};
  const times = edgesTimes.map(({ node }) => node);

  const pileRowSelection = {
    selectedRowKeys: selectedPileRows.map((row) => row.id),
    onChange: (selectedRowKeys, rows) => {
      setSelectedPileRows(rows);
    },
  };

  /**
   * Whenever the data is initially fetched or re-fetched, save the data into the state.
   */
  useEffect(() => {
    setLoading(reFetchData ? reFetchLoading : initialLoading);
    setData(reFetchData ? reFetchData : initialData);
    setError(reFetchData ? reFetchError : initialError);
  }, [initialData, initialError, initialLoading, reFetchError, reFetchData, reFetchLoading]);

  /**
   * Extract the piles whenever the data changes.
   */
  useEffect(() => {
    if (data && data.order && data.order.piles) {
      const {
        order: {
          piles: { edges: edgesPiles },
          route: { edges: edgesRoute },
        },
      } = data || {};
      const newPiles = edgesPiles.map((edge) => edge?.node);
      const route = edgesRoute.map((edge) => edge?.node);
      setPiles(newPiles.filter(notEmpty));
      setRoute(route.filter(notEmpty).map((r) => ({ index: r.index, latitude: r.latitude, longitude: r.longitude })));
    }
  }, [data, setPiles]);

  const onDeleteInventory = (inventory: InventoryNode) => {
    setSelectedInventory(inventory);
    setShowModal(InventoryModalEnum.INVENTORY_DELETE);
  };

  const deleteInventory = () => {
    deleteInventoryFun({ variables: { inventoryId: selectedInventory?.id } }).then(onDismissInventoryDeleteModal);
  };

  /**
   * Open a modal whenever an inventory entry is clicked.
   */
  const onInventoryCellClicked = (e: React.MouseEvent, record: InventoryNode, index: number | undefined) => {
    if (
      record.isMutable &&
      (userHasPermission(Permissions.CAN_DELETE_INVENTORY) ||
        userHasPermissions([Permissions.CAN_CHANGE_INVENTORY, Permissions.CAN_CHANGE_INVENTORY_DETAILS]))
    ) {
      setSelectedInventory(record);
      setShowModal(InventoryModalEnum.INVENTORY_EDIT);
    }
  };

  /**
   * Open a modal to create wagon inventory.
   */
  const onCreateWagonInventoryClicked = () => {
    setShowModal(InventoryModalEnum.INVENTORY_CREATE);
  };

  /**
   * Closes the inventory modal and re-fetches the data.
   */
  const onDismissInventoryModal = () => {
    setSelectedInventory(undefined);
    setShowModal('');
    getOrderDetail();
  };

  const onDismissInventoryDeleteModal = () => {
    setShowModal('');
    setSelectedInventory(undefined);
  };

  /**
   * Closes the inventory modal and re-fetches the data.
   */
  const onDismissWagonInventoryModal = () => {
    setShowModal('');
    getOrderDetail();
  };

  const handlePopoverVisibleChange = (record) => {
    if (showPopover === record.id) {
      setShowPopover('');
    } else if (record.id !== 'title') {
      setSelectedPileRows([record]);
      setShowPopover(record);
    } else if (selectedPileRows.length > 0) {
      setShowPopover(record);
    }
  };

  const [deletePiles] = useMutation(Order.deletePilesFromExistingOrder, {
    onCompleted: async () => {
      await getOrderDetail();
      setToast({
        type: TOAST_TYPES.SUCCESS,
        message: t('desktop.pages.orders.removeFromExisting.success.message'),
        description: t('desktop.pages.orders.removeFromExisting.success.description'),
      });
    },
    onError: ({ graphQLErrors }) => {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.orders.list.error.message'),
        description: graphQLErrors[0].message,
      });
    },
  });

  const handleDeletePilesFromOrder = async (pileIds: string[]) => {
    await deletePiles({
      variables: { pileIdList: pileIds, orderId: order && order.id },
    });
    setSelectedPileRows([]);
  };

  const columnsInventorySummary: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.orders.show.inventorySummary.columns.number'),
      dataIndex: 'stackNumber',
    },
    {
      title: t('desktop.pages.orders.show.inventorySummary.columns.length'),
      dataIndex: 'woodLength',
    },
    {
      title: t('desktop.pages.orders.show.inventorySummary.columns.width'),
      dataIndex: 'stackWidth',
    },
    {
      title: t('desktop.pages.orders.show.inventorySummary.columns.height'),
      dataIndex: 'combinedHeight',
    },
    {
      title: t('desktop.pages.orders.show.inventorySummary.columns.amount'),
      dataIndex: 'totalAmount',
    },
  ];

  let columnsInventories: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.orders.show.inventories.columns.number'),
      dataIndex: ['pile', 'number'],
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.amount'),
      dataIndex: 'amount',
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.count'),
      dataIndex: 'count',
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.time'),
      dataIndex: 'time',
      render: (text) => <Moment format='DD.MM.YYYY, H:mm'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.broached'),
      dataIndex: 'isBroached',
      render: (text) =>
        text
          ? t('desktop.pages.orders.show.inventories.values.broached.true')
          : t('desktop.pages.orders.show.inventories.values.broached.false'),
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.rest'),
      dataIndex: 'rest',
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.createdAt'),
      dataIndex: 'createdAt',
      render: (text) => <Moment format='DD.MM.YYYY, H:mm'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.lastModifiedBy'),
      dataIndex: 'lastmodifiedBy',
      render: (user) => <span>{user ? `${user.firstName} ${user.lastName}` : `-`}</span>,
    },
  ];

  columnsInventories = addRowClickHandler(columnsInventories, onInventoryCellClicked);

  const columnsWagonInventories: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.orders.show.inventories.columns.wagonNumber'),
      dataIndex: 'wagonNumber',
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.number'),
      dataIndex: ['pile', 'number'],
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.amount'),
      dataIndex: 'amount',
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.count'),
      dataIndex: 'count',
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.isWagonReady'),
      dataIndex: 'isWagonReady',
      render: (isWagonReady) =>
        isWagonReady
          ? t('desktop.pages.orders.show.inventories.values.broached.true')
          : t('desktop.pages.orders.show.inventories.values.broached.false'),
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.createdAt'),
      dataIndex: 'createdAt',
      render: (text) => <Moment format='DD.MM.YYYY, H:mm'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.orders.show.inventories.columns.lastModifiedBy'),
      dataIndex: 'lastmodifiedBy',
      render: (user) => <span>{user ? `${user.firstName} ${user.lastName}` : `-`}</span>,
    },
    {
      title: () => (
        <Popover
          placement='leftTop'
          content={
            <>
              <Button
                style={{ display: 'block' }}
                onClick={() => {
                  onCreateWagonInventoryClicked();
                  closePopups();
                }}
                type='link'
                disabled={inventories.length === 0}
              >
                {t('desktop.pages.orders.show.inventories.wagonInventories.add')}
              </Button>
            </>
          }
          trigger='click'
          overlayClassName={'popover-actions'}
        >
          <span>
            <IonIcon mode='md' icon={ellipsisVertical} size='small' />
          </span>
        </Popover>
      ),
      dataIndex: 'action',
      fixed: 'right',
      render: (text, record: any, index) =>
        wagonInventories.length === index + 1 && (
          <StyledTableHeaderButton
            size='large'
            type='link'
            onClick={() => onDeleteInventory(record)}
            disabled={deleteInventoryLoading}
            style={{ padding: 0 }}
          >
            <span>
              <IonIcon mode='md' icon={trashBinOutline} size='small' />
            </span>
          </StyledTableHeaderButton>
        ),
      width: 50,
    },
  ];

  const columnsTimes: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.orders.show.times.columns.begin'),
      dataIndex: 'begin',
      render: (text) => <Moment format='DD.MM.YYYY, H:mm'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.orders.show.times.columns.end'),
      dataIndex: 'end',
      render: (text) => <Moment format='DD.MM.YYYY, H:mm'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.orders.show.times.columns.vehicle'),
      dataIndex: ['vehicle', 'registrationNumber'],
    },
    {
      title: t('desktop.pages.orders.show.times.columns.user'),
      dataIndex: ['user', 'firstName'],
      render: (text, record: any) => (
        <span>
          {text} {record.user && record.user.lastName}
        </span>
      ),
    },
  ];

  const columnsPiles: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.piles.list.columns.plotNumber'),
      dataIndex: ['plot', 'number'],
    },
    {
      title: t('desktop.pages.piles.list.columns.number'),
      dataIndex: 'number',
    },
    {
      title: t('desktop.pages.piles.list.columns.date'),
      dataIndex: 'date',
      render: (text) => <Moment format='DD.MM.YYYY'>{text}</Moment>,
    },
    {
      title: t('desktop.pages.piles.list.columns.location'),
      dataIndex: 'location',
    },
    {
      title: t('desktop.pages.piles.list.columns.locationPlan'),
      dataIndex: 'locationPlan',
    },
    {
      title: t('desktop.pages.piles.list.columns.status'),
      dataIndex: 'status',
      render: (text) => (
        <Tag
          type={text === PileStatus.Disposable ? TagType.PRIMARY : TagType.DEFAULT}
          key={text}
          value={text}
          text={t(`desktop.pages.piles.list.status.${text}`)}
        />
      ),
    },
    {
      title: t('desktop.pages.piles.list.columns.count'),
      dataIndex: 'count',
    },
    {
      title: t('desktop.pages.piles.list.columns.woodtype'),
      dataIndex: 'woodtype',
      render: (text, { woodtype2 }: any) => (
        <span>
          {text}
          <br />
          {woodtype2}
        </span>
      ),
    },
    {
      title: t('desktop.pages.piles.list.columns.sort'),
      dataIndex: 'sort',
    },
    {
      title: t('desktop.pages.piles.list.columns.logLength'),
      dataIndex: 'logLength',
    },
    {
      title: t('desktop.pages.piles.list.columns.rest'),
      dataIndex: 'rest',
    },
    {
      title: t('desktop.pages.piles.list.columns.cubikActual'),
      dataIndex: 'cubikActual',
    },
    {
      title: t('desktop.pages.piles.list.columns.cubikSolidActual'),
      dataIndex: 'cubikSolidActual',
    },
    {
      title: t('desktop.pages.piles.list.columns.cbkNet'),
      dataIndex: 'cbkNet',
    },
    {
      title: t('desktop.pages.piles.list.columns.cbkSolid'),
      dataIndex: 'cbkSolid',
    },
    {
      title: t('desktop.pages.piles.list.columns.pileNumberFurnisher'),
      dataIndex: 'pileNumberFurnisher',
    },
    {
      title: () =>
        selectedPileRows.length > 0 ? (
          <Popover
            placement='leftTop'
            content={<PopoverTitle handleDeletePilesFromOrder={handleDeletePilesFromOrder} piles={selectedPileRows} />}
            onOpenChange={() => handlePopoverVisibleChange({ id: 'title' })}
            trigger='click'
            overlayClassName={'popover-actions'}
          >
            <span>
              <IonIcon mode='md' icon={ellipsisVertical} size='small' />
            </span>
          </Popover>
        ) : (
          <IonIcon mode='md' icon={ellipsisVertical} size='small' color='medium' />
        ),
      dataIndex: 'action',
      fixed: 'right',
      width: 50,
      render: (text, record: any) => (
        <Popover
          placement='leftTop'
          content={<PopoverTitle handleDeletePilesFromOrder={handleDeletePilesFromOrder} piles={[record]} />}
          onOpenChange={() => handlePopoverVisibleChange(record)}
          trigger='click'
          overlayClassName={'popover-actions'}
        >
          <span>
            <IonIcon mode='md' icon={ellipsisVertical} size='small' />
          </span>
        </Popover>
      ),
    },
  ];

  return (
    <div data-testid={TestIds.pages.orders.show.identifier}>
      <Modal
        level={2}
        title={t('desktop.pages.orders.show.inventoryEdit.title')}
        open={showModal === InventoryModalEnum.INVENTORY_EDIT}
        onCancel={onDismissInventoryModal}
        tabComponents={[
          {
            tabName: t('desktop.pages.orders.show.inventoryEdit.title'),
            component: <InventoryEdit closeModal={onDismissInventoryModal} inventory={selectedInventory} />,
          },
        ]}
      />
      <Modal
        level={2}
        destroyOnClose
        title={t('desktop.pages.orders.show.wagonInventoryCreate.title')}
        open={showModal === InventoryModalEnum.INVENTORY_CREATE}
        onCancel={onDismissWagonInventoryModal}
        tabComponents={[
          {
            tabName: t('desktop.pages.orders.show.inventoryCreate.title'),
            component: (
              <WagonInventoryForm
                deleteEnabled={false}
                createEnabled
                order={order || undefined}
                onSubmit={onDismissInventoryModal}
              />
            ),
          },
        ]}
      />

      <ConfirmModal
        level={2}
        title={t('desktop.pages.orders.show.inventories.wagonInventories.delete.longTitle')}
        open={showModal === InventoryModalEnum.INVENTORY_DELETE}
        onCancel={onDismissInventoryDeleteModal}
        onOk={deleteInventory}
        okText={t('desktop.pages.orders.show.inventories.wagonInventories.delete.title')}
        cancelText={t('general.cancel')}
        tabComponents={[
          {
            tabName: t('desktop.pages.orders.show.inventories.wagonInventories.delete.title'),
            component: <InventoryDeleteConfirm errors={deleteInventoryError} />,
          },
        ]}
      />

      <Wrapper>
        {(loading || deleteInventoryLoading) && (
          <IonRow>
            <IonCol className='ion-text-center'>
              <IonSpinner name='crescent' />
            </IonCol>
          </IonRow>
        )}

        {error && (
          <Alert
            message={t('desktop.pages.orders.show.error.message')}
            description={t('desktop.pages.orders.show.error.description')}
            type='error'
            showIcon
          />
        )}

        {data && data.order && (
          <>
            <Detail order={data.order} />
            <StyledOrderDetailTable
              title={() => t('desktop.pages.orders.show.inventorySummary.title')}
              columns={columnsInventorySummary}
              dataSource={inventorySummary}
              rowKey={(val) => Object.values(val as Record<string, unknown>).join('-')}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            <StyledOrderDetailTable
              title={() => t('desktop.pages.orders.show.inventories.title')}
              columns={columnsInventories}
              dataSource={inventories}
              rowKey='id'
              pagination={false}
              scroll={{ x: 'max-content' }}
              loading={initialLoading || reFetchLoading || deleteInventoryLoading}
            />
            <StyledOrderDetailTable
              title={() => t('desktop.pages.orders.show.inventories.wagonInventories.title')}
              columns={columnsWagonInventories}
              dataSource={wagonInventories}
              rowKey='id'
              pagination={false}
              scroll={{ x: 'max-content' }}
              loading={initialLoading || reFetchLoading || deleteInventoryLoading}
            />
            <StyledOrderDetailTable
              title={() => t('desktop.pages.orders.show.times.title')}
              columns={columnsTimes}
              dataSource={times}
              rowKey='id'
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            <StyledOrderDetailTable
              title={() => t('desktop.pages.orders.show.piles.title')}
              rowSelection={pileRowSelection}
              columns={columnsPiles}
              loading={reFetchLoading}
              dataSource={piles}
              rowKey='id'
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            {order && <StatusChangesList orderId={order.id} />}
          </>
        )}
      </Wrapper>
    </div>
  );
};
