import React, { FC, useContext } from 'react';
import { FormItem, FormItemProps, Select, SelectProps } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { FormikContext } from 'formik';

export interface MultiSelectProps extends Omit<SelectProps, 'options'>, Pick<FormItemProps, 'label'> {
  options?: Array<{ title: string; value: string }>;
  required?: boolean;
}

export const MultiSelect: FC<MultiSelectProps> = ({ options, label, name, required, placeholder, ...props }) => {
  const { t } = useTranslation();
  const { values } = useContext(FormikContext);

  return (
    <FormItem
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      style={{ width: '100%' }}
      label={label}
      name={name}
      required={required}
    >
      <Select
        {...props}
        allowClear
        placeholder={placeholder || t('forms.validation.info.noValueSet')}
        name={name}
        optionFilterProp='children'
        mode='multiple'
      >
        {options &&
          options.length > 0 &&
          options
            .sort(({ value }) => (values[name]?.includes(value) ? -1 : 1))
            .map(({ value, title }, index) => (
              <Select.Option key={`${name}_${index}`} value={value}>
                {title}
              </Select.Option>
            ))}
      </Select>
    </FormItem>
  );
};
