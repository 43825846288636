import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonAlert, IonContent, IonHeader, IonItem, IonLabel, IonMenuToggle, IonTitle, IonToolbar } from '@ionic/react';

import { Routes } from '@src/global';

import {
  BottomInformationWrapper,
  CheckInIonItem,
  CheckInIonSpinner,
  IonItemHeader,
  MenuIconStyles,
  StyledIonList,
  StyledIonMenu,
  StyledNote,
} from './Menu.styles';
import { LocationContext } from '@src/components/Mobile/Providers';
import { MeContext, ToastContext } from '@src/components/Providers';
import { Account } from '@src/graphql/Account';
import { useMutation } from 'react-apollo';
import { ActiveInactiveStatus } from '@src/components/Mobile/Sections/Page/Menu/NetworkStatus/ActiveInactiveStatus';
import { DisconnectOutlined, ExportOutlined, ImportOutlined, LogoutOutlined, WifiOutlined } from '@ant-design/icons';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';
import { ApolloError } from 'apollo-client';

export interface MenuProps {
  withFooter?: boolean;
}

export const Menu: FC<MenuProps> = ({ withFooter }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { isOnline, setIsOnline } = useContext(NetworkContext);
  const location = useContext(LocationContext);
  const { me, refetchMe } = useContext(MeContext);
  const [checkOut, { loading: checkOutLoading }] = useMutation(Account.checkOut);
  const [checkIn, { loading: checkInLoading }] = useMutation(Account.checkIn);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [changingCheck, setChangingCheck] = useState(false);
  const { setIonToast } = useContext(ToastContext);

  const updateCheckInAndOut = async () => {
    if (!isOnline) return;

    try {
      // we are not using addJob here because this should not work while being offline
      if (!me?.checkedIn) {
        await checkIn({ variables: { checkInDispatchedAt: new Date() } });
      } else {
        await checkOut({ variables: { checkOutDispatchedAt: new Date() } });
      }

      await refetchMe();
    } catch (errors) {
      const { networkError, graphQLErrors } = errors as ApolloError;

      if (networkError) {
        setIsOnline(false);
      } else {
        graphQLErrors.forEach(({ message }) => {
          setIonToast({
            message,
            show: true,
          });
        });
      }
    }
  };

  const handleCheckInAndOut = () => {
    setChangingCheck(true);

    updateCheckInAndOut().then(() => {
      setChangingCheck(false);
    });
  };

  const handleLogout = () => {
    push(Routes.LOGOUT);
  };

  return (
    <StyledIonMenu side='end' contentId='main-content' menuId='main-menu' withFooter={withFooter}>
      <IonHeader>
        <IonToolbar>
          <IonTitle />
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <StyledIonList>
          <IonItemHeader>{t('mobile.containers.menu.navigation')}</IonItemHeader>
          <IonMenuToggle menu='main-menu'>
            <IonItem routerLink={`${Routes.HOME}${Routes.ORDERS}`}>
              <IonLabel>{t('mobile.navigation.home.title')}</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle menu='main-menu'>
            <IonItem routerLink={Routes.OFFLINE_MAPS}>
              <IonLabel>{t('mobile.navigation.offlineMaps')}</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle menu='main-menu'>
            <IonItem routerLink={Routes.PASSWORD} disabled={!isOnline}>
              <IonLabel>{t('mobile.navigation.passwordChange')}</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonItemHeader>{t('mobile.containers.menu.actions')}</IonItemHeader>
          {me?.client?.canUseTimeRecording && (
            <CheckInIonItem onClick={handleCheckInAndOut} disabled={!isOnline || changingCheck}>
              <IonLabel>
                {me?.checkedIn ? <ExportOutlined style={MenuIconStyles} /> : <ImportOutlined style={MenuIconStyles} />}
                {me?.checkedIn ? 'Check-Out' : 'Check-In'}
                {(checkOutLoading || checkInLoading) && <CheckInIonSpinner name='crescent' />}
              </IonLabel>
            </CheckInIonItem>
          )}
          <IonMenuToggle menu='main-menu'>
            <IonItem onClick={() => setShowAlert(true)} disabled={!isOnline}>
              <IonLabel>
                <LogoutOutlined style={MenuIconStyles} />
                {t('mobile.navigation.logout.title')}
              </IonLabel>
            </IonItem>
          </IonMenuToggle>

          {window.env.ENV_DEVELOPMENT && (
            <IonItem onClick={() => setIsOnline((previous) => !previous)}>
              <IonLabel>
                {isOnline ? (
                  <>
                    <DisconnectOutlined style={MenuIconStyles} />
                    {t('mobile.navigation.action.debug.goOffline')}
                  </>
                ) : (
                  <>
                    <WifiOutlined style={MenuIconStyles} />
                    {t('mobile.navigation.action.debug.goOnline')}
                  </>
                )}
              </IonLabel>
            </IonItem>
          )}

          <IonItemHeader>{t('mobile.containers.menu.information')}</IonItemHeader>
          <IonItem>
            <IonLabel>{t('general.accuracy')}</IonLabel>
            <StyledNote slot='end'>{location.accuracy ? Math.round(location.accuracy) : '-'} m</StyledNote>
          </IonItem>

          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={t('mobile.navigation.logout.title')}
            subHeader={t('mobile.navigation.logout.alert.message')}
            buttons={[
              {
                text: t('mobile.navigation.logout.alert.cancel'),
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: t('mobile.navigation.logout.alert.accept'),
                handler: handleLogout,
              },
            ]}
          />
        </StyledIonList>

        <BottomInformationWrapper>
          <ActiveInactiveStatus
            text={t(`mobile.containers.networkStatus.${isOnline ? 'online' : 'offline'}`)}
            active={isOnline}
          />
          {me?.client?.canUseTimeRecording && (
            <ActiveInactiveStatus
              active={!!me?.checkedIn}
              text={t(`mobile.containers.checkedInStatus.${me?.checkedIn ? 'in' : 'out'}`)}
            />
          )}
        </BottomInformationWrapper>
      </IonContent>
    </StyledIonMenu>
  );
};
