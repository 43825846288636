import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import * as Yup from 'yup';

import { Formik, FormikValues } from 'formik';
import { PileNode } from '@src/generated/schema';
import { Switch } from '@src/components/Desktop/Containers/Switch/Switch';
import { isFieldRequired } from '@src/helpers';
import { SubmitButton } from 'formik-antd';
import { FormikHelpers } from 'formik/dist/types';
import { ButtonCol } from '@src/components/Mobile/Containers/MapWrapper/FilterDrawer/FilterDrawer.styles';
import { AggregationsSelect } from '@src/components/Mobile/Containers/MapWrapper/FilterDrawer/AggregationSelect';
import {
  getAggregationFromPiles,
  PileAggregation,
} from '@src/components/Mobile/Containers/MapWrapper/FilterDrawer/AggregationSelect/AggregationsSelect';
import { useTranslation } from 'react-i18next';

export interface DownloadDrawer {
  initialValue?: PileAggregationForm;
  onCloseDrawer: () => void;
  onSubmit: (state: PileAggregationForm, filteredPiles: PileNode[], filtersAreActive: boolean) => void;
  piles?: PileNode[];
  visible?: boolean;
}

export interface PileAggregationForm {
  includeBroached: boolean;
  pileAggregations: PileAggregation[];
}

export const FilterDrawer: React.FC<DownloadDrawer> = ({ visible, initialValue, onCloseDrawer, onSubmit, piles }) => {
  const { t } = useTranslation();
  const initialValues: PileAggregationForm = {
    includeBroached: initialValue?.includeBroached || false,
    pileAggregations: initialValue?.pileAggregations || getAggregationFromPiles(piles, false),
  };

  const validationSchema = Yup.object().shape({
    includeBroached: Yup.boolean().default(false),
    pileAggregations: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        value: Yup.boolean().default(true),
        woodType: Yup.string(),
        type: Yup.string(),
        length: Yup.string(),
        sort: Yup.string(),
      }),
    ),
  });

  /**
   * Checks if a given pile belongs to any entry of aggregations.
   */
  const pileBelongsToAnyAggregation = (pile: PileNode, aggregations: PileAggregation[]) =>
    aggregations.filter(
      (agg) =>
        pile.woodtype === agg.woodType &&
        pile.sort === agg.sort &&
        pile.logLength === agg.length &&
        pile.type === agg.type,
    ).length > 0;

  /**
   * Checks if any of the values of the form have changed aka if the form has any filters active.
   */
  const valuesHaveChanged = (values: FormikValues) =>
    values.includeBroached || values.pileAggregations.filter((agg) => !agg.value).length > 0;

  /**
   * Returns all piles that would be visible in the map if current selection of filters is applied to piles.
   */
  const getFilteredPiles = (values: PileAggregationForm | FormikValues) => {
    if (!piles) return [];

    return piles
      .filter((pile) =>
        pileBelongsToAnyAggregation(
          pile,
          values.pileAggregations.filter((agg) => agg.value),
        ),
      )
      .filter((pile) => values.includeBroached || !pile.isBroached);
  };

  const onFormSubmit = (values: PileAggregationForm, actions: FormikHelpers<PileAggregationForm>) => {
    onSubmit(values, getFilteredPiles(values), valuesHaveChanged(values));
    actions.setSubmitting(false);
  };

  /**
   * Resets the form to its initial values.
   */
  const resetForm = (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    setFieldValue('includeBroached', false);
    setFieldValue('pileAggregations', getAggregationFromPiles(piles, false));
  };

  return (
    <Drawer
      title={t('mobile.containers.mapWrapper.filterDrawer.title')}
      open={visible}
      placement='bottom'
      onClose={onCloseDrawer}
      getContainer={false}
      height='80vh'
    >
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ isValid, handleSubmit, values, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Switch
                label={t('mobile.containers.mapWrapper.filterDrawer.label.includeBroached')}
                required={isFieldRequired('includeBroached', validationSchema)}
                name='includeBroached'
              />
              <AggregationsSelect piles={piles} />

              <ButtonCol md={24}>
                <Button
                  disabled={!valuesHaveChanged(values) || isSubmitting}
                  onClick={() => resetForm(setFieldValue)}
                  icon={<SyncOutlined />}
                >
                  {t('mobile.containers.mapWrapper.filterDrawer.resetButton')}
                </Button>
              </ButtonCol>

              <ButtonCol md={24}>
                <SubmitButton type='primary' disabled={!isValid || isSubmitting || !getFilteredPiles(values).length}>
                  {t('mobile.containers.mapWrapper.filterDrawer.submitButton', {
                    count: getFilteredPiles(values).length,
                  })}
                </SubmitButton>
              </ButtonCol>
            </form>
          )}
        </Formik>
      </>
    </Drawer>
  );
};
