import React, { FC, SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonText } from '@ionic/react';
import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { BackgroundMode } from '@ionic-native/background-mode';
import { useQuery } from '@apollo/react-hooks';
import { ReloadOutlined } from '@ant-design/icons';

import 'react-leaflet-markercluster/dist/styles.min.css';
import 'leaflet/dist/leaflet.css';
import 'font-awesome/css/font-awesome.min.css';

import { RelayItemsPerPageMobile } from '@src/global';
import { PileNode, OrderNodeConnection, OrderNodeEdge, Maybe, RouteNodeInput, RouteNode } from '@src/generated/schema';
import { Order } from '@src/graphql/Order';
import { ToastContext } from '@src/components/Providers';
import { OfflineHandlerContext } from '@src/components/Mobile/Providers';
import { Map } from '@src/components/Containers/Map';
import { PileDetailDrawer } from '@src/components/Mobile/Containers/MapWrapper/PileDetailDrawer';
import { DownloadDrawer } from '@src/components/Mobile/Containers/MapWrapper/DownloadDrawer';

import { StyledCustomMapControls, StyledButton, StyledH4, StyledPage, StyledPilesSearch } from './MapWrapper.styles';
import { FilterDrawer } from '@src/components/Mobile/Containers/MapWrapper/FilterDrawer';
import { PileAggregationForm } from '@src/components/Mobile/Containers/MapWrapper/FilterDrawer/FilterDrawer';
import {
  OrderCacheFilterType,
  ORDERS_QUERY_ACTIVE_ONLY,
  ORDERS_QUERY_ALL,
  ORDERS_QUERY_FALLBACK,
  ORDERS_QUERY_FINISHED_ONLY,
  OrderVariables,
} from '@src/components/Mobile/Providers/OfflineHandler/OfflineHandlerContext';
import { NetworkContext } from '@src/components/Mobile/Providers/Network';

export const MapWrapper: FC = () => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const { isOnline } = useContext(NetworkContext);
  const { jobs, refetchOrders } = useContext(OfflineHandlerContext);
  const { setIonToast } = useContext(ToastContext);
  const [piles, setPiles] = useState<PileNode[]>([]);
  const [mapCenterReset, setMapCenterReset] = useState<boolean>(false);
  const [mapMarkers, setMapMarkers] = useState<PileNode[]>([]);

  // Route Management
  const [routeMarkers, setRouteMarkers] = useState<RouteNodeInput[]>([]);
  const [showRoute, setShowRoute] = useState<boolean>(false);

  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false);
  const [filtersAreActive, setFiltersActive] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<PileAggregationForm | undefined>(undefined);

  const [hideBroachedPiles, setHideBroachedPiles] = useState<boolean>(true);
  const [isFocusOnReloadButton, setIsFocusOnReloadButton] = useState<boolean>(false);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [selectedMarker, setSelectedMarker] = useState<PileNode | undefined>();
  const [showPileDrawer, setShowPileDrawer] = useState<boolean>(false);
  const [showDownloadDrawer, setShowDownloadDrawer] = useState<boolean>(false);

  const [currentOrder, setCurrentOrder] = useState<OrderNodeEdge | undefined>();
  const [currentOrderCache, setCurrentOrderCache] = useState<OrderCacheFilterType | undefined>(undefined);

  // try to get the order from different caches
  const { data: activeData } = useQuery<{ prefetchOrders: OrderNodeConnection }>(Order.getDriverOrders, {
    variables: { first: RelayItemsPerPageMobile, ...ORDERS_QUERY_ACTIVE_ONLY },
    fetchPolicy: 'cache-only',
  });
  const { data: finishedData } = useQuery<{ prefetchOrders: OrderNodeConnection }>(Order.getDriverOrders, {
    variables: { first: RelayItemsPerPageMobile, ...ORDERS_QUERY_FINISHED_ONLY },
    fetchPolicy: 'cache-only',
  });
  const { data: allData } = useQuery<{ prefetchOrders: OrderNodeConnection }>(Order.getDriverOrders, {
    variables: { first: RelayItemsPerPageMobile, ...ORDERS_QUERY_ALL },
    fetchPolicy: 'cache-only',
  });

  /**
   * Helper function that tries to find the order in the prefetch data that was returned in a data set.
   */
  const findOrderInPrefetchData = useCallback(
    (data?) => data?.prefetchOrders?.edges.find((edge) => edge?.node?.id === orderId),
    [orderId],
  );

  /**
   * The current order can be fetched from different types of caches: all orders, finished orders and open
   * orders. This effect searches the order in the caches and sets the value for easier access in the future.
   * It also saves where the order is contained.
   */
  useEffect(() => {
    const activeOrder = findOrderInPrefetchData(activeData);

    // active order cache
    if (activeOrder) {
      setCurrentOrderCache('activeOnly');
      setCurrentOrder(activeOrder);

      return undefined;
    }

    const finishedOrder = findOrderInPrefetchData(finishedData);

    // finished order cache
    if (finishedOrder) {
      setCurrentOrderCache('finished');
      setCurrentOrder(finishedOrder);

      return undefined;
    }

    // from all order cache
    const allOrder = findOrderInPrefetchData(allData);

    setCurrentOrderCache('all');
    setCurrentOrder(allOrder || undefined);
  }, [activeData, finishedData, allData, findOrderInPrefetchData]);

  const onMarkerSelect = (marker: PileNode) => {
    setSelectedMarker(marker);
    setShowPileDrawer(true);
  };

  const onCloseDrawer = () => {
    setShowPileDrawer(false);
    setShowDownloadDrawer(false);
    setShowFilterDrawer(false);
    setSelectedMarker(undefined);
  };

  const handleRefetchOrders = async () => {
    if (!isOnline) {
      setIonToast({
        show: true,
        message: t('general.offlineQuery'),
      });

      return;
    }

    if (isOnline && !!jobs.length) {
      setIonToast({
        show: true,
        message: t('general.offlineProcessing'),
      });

      return;
    }

    setIsRefetching(true);

    // Use the current dataOrders query to refetch all cached orders if the user
    // fetched more than the initial "RelayItemsPerPageMobile"
    let variables: OrderVariables = { ...ORDERS_QUERY_FALLBACK, first: activeData?.prefetchOrders.edges.length };

    // if the order was found not in the cached list of active orders, change the variables in a way that the correct
    // cache is targeted
    if (currentOrderCache === 'finished') {
      variables = { ...variables, ...ORDERS_QUERY_FINISHED_ONLY };
    } else if (currentOrderCache === 'all') {
      variables = { ...variables, ...ORDERS_QUERY_ALL };
    }

    refetchOrders(variables);

    // Using a timeout to fake a loading indicator as "refetchOrders" is not returning a promise to await
    // and "loading" of "useQuery" is always returning "false". Probably due to fetch policy set to "cache only"
    await new Promise((resolve) => setTimeout(resolve, 3000));

    setIsRefetching(false);
  };

  const handleReloadPress: (event: SyntheticEvent) => void = (event: SyntheticEvent) => {
    if (event.type === 'blur' || event.type === 'mouseout') {
      setIsFocusOnReloadButton(false);

      return;
    }

    if (isFocusOnReloadButton) {
      handleRefetchOrders();
      setIsFocusOnReloadButton(!isFocusOnReloadButton);
    }

    setIsFocusOnReloadButton(!isFocusOnReloadButton);
  };

  /**
   * Whenever the map center is reset by setting the value of `mapCenterReset` to true, it is set to false again.
   * This is done because the map uses this prop to re-render itself.
   */
  useEffect(() => {
    if (mapCenterReset) {
      setMapCenterReset(false);
    }
  }, [mapCenterReset, setMapCenterReset]);

  /**
   * @description Handles the pile search.
   */
  const handleSearch = (values: PileNode[]) => {
    setMapMarkers(values);
    setHideBroachedPiles(false);
  };

  /**
   * Handles the submit of the filter options.
   */
  const handleFilterSubmit = (values: PileAggregationForm, filteredPiles: PileNode[], filtersActive: boolean) => {
    setCurrentFilter(values);
    setFiltersActive(filtersActive);
    setMapMarkers(filteredPiles);
    setMapCenterReset(true);
    setShowFilterDrawer(false);
  };

  const onClickFilter = () => {
    setShowFilterDrawer(true);
  };

  /**
   * @description Start an interval when the component did mount to "poll" the driverOrders query.
   */
  useEffect(() => {
    // There is no need to for an asynchronous interval that waits for the refetchOrders query before issuing a
    // new query. The refetchOrders method handles overlapping queries on its own.
    const interval = setInterval(() => {
      handleRefetchOrders();
    }, 1000 * 60 * 30);

    // refetch the orders when the component is mounted (previously this would be called when the app is opened
    // from the background, but the library was not supported on newer Android versions)
    handleRefetchOrders();

    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * @description Update the mapMarkers and Piles every time the driverOrders data was updated.
   */
  useEffect(() => {
    const markers: Maybe<PileNode[]> | any = currentOrder?.node?.piles?.edges.map((node) => node?.node);
    const orderNodeRouteMarkers: Maybe<RouteNode[]> | any = currentOrder?.node?.route?.edges.map((node) => node?.node);
    const filteredMarkers: PileNode[] = hideBroachedPiles
      ? markers?.filter((marker: PileNode) => !marker.isBroached)
      : markers;
    setPiles(markers || []);
    const routeMarkerInput = orderNodeRouteMarkers?.map((r) => ({
      index: r.index,
      latitude: r.latitude,
      longitude: r.longitude,
    }));
    setRouteMarkers(routeMarkerInput || []);
    setMapMarkers(filteredMarkers || []);

    // Handling piles and markers as a side effect of a state change of 'hideBroachedPiles' is already
    // taken care of somewhere else. Thank you anyway eslint.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder]);

  return (
    <StyledPage>
      {piles?.length > 0 ? (
        <>
          <StyledCustomMapControls className={ScreenOrientation?.type}>
            <StyledPilesSearch piles={piles} onSearch={handleSearch} resetPilesSearch={hideBroachedPiles} />

            <StyledButton
              disabled={isRefetching}
              onBlur={handleReloadPress}
              onClick={handleReloadPress}
              onMouseOut={handleReloadPress}
            >
              {isFocusOnReloadButton ? t('general.reloadPiles') : null}

              <ReloadOutlined spin={isRefetching} />
            </StyledButton>
          </StyledCustomMapControls>

          <Map
            markerFilterIsActive={filtersAreActive}
            markerHeight={50}
            mapCenterReset={mapCenterReset}
            markers={mapMarkers}
            onDownloadClick={() => setShowDownloadDrawer(true)}
            onMarkerClick={onMarkerSelect}
            onToggleMarkerFilter={onClickFilter}
            routeMarkers={routeMarkers}
            showRoute={showRoute}
            toggleShowRoute={setShowRoute}
            showZoomActionBar
            orderId={orderId}
          />

          {!!selectedMarker && (
            <PileDetailDrawer pile={selectedMarker} visible={showPileDrawer} onCloseDrawer={onCloseDrawer} />
          )}
          <DownloadDrawer visible={showDownloadDrawer} onCloseDrawer={onCloseDrawer} />
          <FilterDrawer
            initialValue={currentFilter}
            piles={piles}
            visible={showFilterDrawer}
            onCloseDrawer={onCloseDrawer}
            onSubmit={handleFilterSubmit}
          />
        </>
      ) : (
        <IonText>
          <StyledH4>{t('mobile.pages.piles.list.noPiles')}</StyledH4>
        </IonText>
      )}
    </StyledPage>
  );
};
