import React, { FC, useState, useEffect } from 'react';
import { StyledModalTab, StyledModalHeadline, StyledModal, StyledModalHeader, EmptyStyleElement } from './Modal.styles';
import { ModalProps as AntdModalProps } from 'antd/lib/modal/';

interface TabComponentTyp {
  component: JSX.Element;
  isMap?: boolean;
  tabName: string;
}

export type TabComponentsType = Array<TabComponentTyp>;

export type ModalLevel = 1 | 2 | 3;

export interface ModalProps extends AntdModalProps {
  level?: ModalLevel;
  tabComponents: TabComponentsType;
  title: string;
}

interface HeaderProps {
  setTabIndex: (newIndex: number) => void;
  tabComponents: TabComponentsType;
  tabIndex: number;
  title: string;
}

const ModalHeader: FC<HeaderProps> = ({ title, tabComponents, tabIndex, setTabIndex }) => (
  <StyledModalHeader>
    <StyledModalHeadline>{title}</StyledModalHeadline>
    {tabComponents.length > 1 &&
      tabComponents.map((el, idx) => (
        <StyledModalTab
          key={el.tabName}
          selected={tabIndex === idx}
          onClick={() => {
            setTabIndex(idx);
          }}
        >
          {el.tabName}
        </StyledModalTab>
      ))}
    <EmptyStyleElement />
  </StyledModalHeader>
);

export const Modal: FC<ModalProps> = ({ children, tabComponents, level = 1, ...props }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isMap, setIsMap] = useState(false);

  useEffect(() => {
    tabComponents[tabIndex].isMap ? setIsMap(true) : setIsMap(false);
  }, [tabIndex, tabComponents]);

  return (
    <StyledModal
      {...props}
      level={level}
      isMap={isMap}
      title={
        <ModalHeader title={props.title} tabComponents={tabComponents} tabIndex={tabIndex} setTabIndex={setTabIndex} />
      }
      footer={null}
    >
      {tabComponents[tabIndex].component}
    </StyledModal>
  );
};

export const ConfirmModal: FC<ModalProps> = ({ children, tabComponents, level = 1, ...props }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isMap, setIsMap] = useState(false);

  useEffect(() => {
    tabComponents[tabIndex].isMap ? setIsMap(true) : setIsMap(false);
  }, [tabIndex, tabComponents]);

  return (
    <StyledModal
      destroyOnClose
      {...props}
      level={level}
      isMap={isMap}
      title={
        <ModalHeader title={props.title} tabComponents={tabComponents} tabIndex={tabIndex} setTabIndex={setTabIndex} />
      }
    >
      {tabComponents[tabIndex].component}
    </StyledModal>
  );
};
