import React, { FC, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { TimeSheetNode } from '@src/generated/schema';
import { DatePicker } from '@src/components/Desktop/Containers/DatePicker';
import { Form as FormWrapper, Row, Column } from '@src/components/Desktop/Elements/Wrapper/Form';

import { useMutation } from 'react-apollo';
import { Account } from '@src/graphql/Account';
import { Formik } from 'formik';
import moment from 'moment';
import { SubmitButton } from 'formik-antd';
import { ToastContext, TOAST_TYPES } from '@src/components/Providers';
import { isFieldRequired } from '@src/helpers';
import { APIError } from '@src/components/Elements/Wrapper/Form';

export interface TimeSheetEditProps {
  closeModal: () => void;
  timeSheet: TimeSheetNode | undefined;
}

export interface FormValues {
  checkInDispatchedAt: string;
  checkOutDispatchedAt: string;
}

export const TimeSheetEdit: FC<TimeSheetEditProps> = ({ closeModal, timeSheet }) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const [apiError, setApiError] = useState<any>();
  const [editTimeSheet, { error }] = useMutation(Account.editTimeSheet);

  useEffect(() => {
    setApiError(error);
  }, [setApiError, error]);

  // the display error message is resetted if the timeSheet changes
  // this way we prevent unnecessary rerenders
  useEffect(() => {
    setApiError(undefined);
  }, [setApiError, timeSheet]);

  const handleSubmit = async (values: FormValues) => {
    try {
      const { data: data_ } = await editTimeSheet({
        variables: {
          timesheet: timeSheet?.id,
          checkInDispatchedAt: values.checkInDispatchedAt,
          checkOutDispatchedAt: values.checkOutDispatchedAt,
        },
        refetchQueries: ['timeSheets'],
      });

      if (data_) {
        closeModal();
        setToast({
          type: TOAST_TYPES.SUCCESS,
          message: t('desktop.pages.accounts.edit.form.success.message'),
          description: t('desktop.pages.accounts.edit.form.success.description'),
        });
      }
    } catch {
      return false;
    }
  };

  const i18nFormFieldRequired = 'forms.validation.errors.fieldIsRequired';
  const validationSchema = Yup.object().shape({
    checkInDispatchedAt: Yup.date()
      .default(moment(timeSheet?.checkInDispatchedAt))
      .required(t(i18nFormFieldRequired, { field: t('desktop.pages.deliveryStatus.list.columns.dateBegin') })),
    checkOutDispatchedAt: Yup.date()
      .default(moment(timeSheet?.checkOutDispatchedAt))
      .required(t(i18nFormFieldRequired, { field: t('desktop.pages.deliveryStatus.list.columns.dateEnd') }))
      .when(
        'checkInDispatchedAt',
        (checkInDispatchedAt, schema) =>
          checkInDispatchedAt &&
          schema.min(
            moment(checkInDispatchedAt).add(1, 'seconds'),
            t('desktop.pages.deliveryStatus.add.validation.dateEndAfterDateStart'),
          ),
      ),
  });

  return (
    <Formik
      initialValues={{
        checkInDispatchedAt: timeSheet?.checkInDispatchedAt,
        checkOutDispatchedAt: timeSheet?.checkOutDispatchedAt,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      // to prevent circular validation we touch fields on mount
      // time inconsistency will always appear at the check out
      initialTouched={{
        checkInDispatchedAt: true,
        checkOutDispatchedAt: false,
      }}
      validateOnMount
    >
      {({ isValid, values, validateForm }) => (
        <FormWrapper>
          <Row>
            <Column>
              <DatePicker
                onChange={validateForm}
                defaultValue={moment(values.checkInDispatchedAt)}
                showTime
                format='DD.MM.YYYY, HH:mm'
                name='checkInDispatchedAt'
                required={isFieldRequired('dateBegin', validationSchema)}
                label={t('desktop.pages.accounts.show.columns.checkInDispatchedAt')}
              />
            </Column>
            <Column>
              <DatePicker
                onChange={validateForm}
                defaultValue={values.checkOutDispatchedAt ? moment(values.checkOutDispatchedAt) : undefined}
                showTime
                format='DD.MM.YYYY, HH:mm'
                name='checkOutDispatchedAt'
                required={isFieldRequired('dateBegin', validationSchema)}
                label={t('desktop.pages.accounts.show.columns.checkOutDispatchedAt')}
              />
            </Column>
          </Row>
          <Row justify='space-between'>
            <SubmitButton type='primary' disabled={!isValid}>
              {t('general.confirm')}
            </SubmitButton>
          </Row>
          {apiError && <APIError errors={apiError} />}
        </FormWrapper>
      )}
    </Formik>
  );
};
