import { PileNode } from '@src/generated/schema';
import { Routes } from '@src/global';
import { Drawer, Descriptions } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { StyledButton } from './PileDetailDrawer.styles';

export interface PileDetailDrawerProps {
  enableBooking?: boolean;
  enableEditing?: boolean;
  onCloseDrawer?: () => void;
  pile: PileNode;
  visible?: boolean;
}

export const PileDetailDrawer: React.FC<PileDetailDrawerProps> = ({
  pile,
  visible,
  onCloseDrawer,
  enableBooking = true,
  enableEditing = false,
}) => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const title = pile.client?.name ? `${pile.number} - ${pile.client?.name}` : pile.number;

  return (
    <Drawer
      title={title}
      open={!!visible}
      placement='bottom'
      onClose={onCloseDrawer}
      getContainer={false}
      height='70vh'
    >
      {pile && (
        <Descriptions column={2}>
          <Descriptions.Item label={t('mobile.pages.piles.labels.location')}>{pile.location}</Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.countActual')}>{pile.countActual}</Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.pileNumberFurnisher')}>
            {pile.pileNumberFurnisher}
          </Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.sort')}>{pile.sort}</Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.woodtype')}>
            {pile.woodtype} {pile.woodtype2 && `| ${pile.woodtype2}`}
          </Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.logLength')}>{pile.logLength}</Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.cubikActual')}>{pile.cubikActual}</Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.cubikSolidActual')}>
            {pile.cubikSolidActual}
          </Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.rest')}>{pile.rest}</Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.type')}>{pile.type}</Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.longitude')}>{pile.longitude}</Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.latitude')}>{pile.latitude}</Descriptions.Item>

          <Descriptions.Item label={t('mobile.pages.piles.labels.locationPlan')}>{pile.locationPlan}</Descriptions.Item>
        </Descriptions>
      )}
      {enableBooking && (
        <StyledButton
          type='button'
          color='primary'
          fill='solid'
          expand='block'
          routerLink={`${Routes.ORDERS}/${orderId}${Routes.PILES}/${pile?.id}${Routes.ADD}`}
        >
          {t('mobile.pages.piles.bookButton')}
        </StyledButton>
      )}
      {enableEditing && (
        <StyledButton
          type='button'
          color='primary'
          fill='solid'
          expand='block'
          routerLink={`${Routes.PILES}/${pile?.id}${Routes.EDIT}`}
        >
          {t('mobile.pages.piles.editButton')}
        </StyledButton>
      )}
    </Drawer>
  );
};
