import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { ButtonType } from '@src/components/Elements/ButtonLink/ButtonLink';

export const ButtonLinkStyle = css`
  color: ${({ theme }) => theme.color.black};
  margin: 0.93em 0.75em;
  padding: 0.57em 0.72em;
  border-radius: ${({ theme }) => theme.global.borderRadius}px;
  font-size: 0.9rem;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.black};
    text-decoration: underline;
  }
`;

export const SelectedButtonLinkStyle = css`
  color: ${({ theme }) => theme.color.twine};
  background-color: ${({ theme }) => transparentize(0.8, theme.color.twine)};
  font-weight: ${({ theme }) => theme.fontWeight.primary.bold};

  &:hover {
    color: ${({ theme }) => theme.color.twine};
    text-decoration: underline;
  }
`;

export const StyledButtonLink = styled(ReactRouterLink as React.ComponentType<LinkProps>)`
  white-space: nowrap;
  ${ButtonLinkStyle}
  ${({ type }) => type === ButtonType.SELECTED && SelectedButtonLinkStyle}
`;
