import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { DeliveryStatusNode, DeliveryStatusNodeConnection } from '@src/generated/schema';
import { useTranslation } from 'react-i18next';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { StyledButton, StyledIonRow, StyledTable } from './Table.styles';
import { IonCol, IonIcon, IonSpinner, IonTitle } from '@ionic/react';
import { Pagination } from '@src/components/Desktop/Elements';
import { Alert, Popover } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import { MeContext, Permissions, TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { ColumnProps } from 'antd/lib/table/Column';
import { add, ellipsisVertical, funnel } from 'ionicons/icons';
import { DeliveryStatus } from '@src/graphql/DeliveryStatus';
import { PopoverRow } from '@src/components/Desktop/Pages/Private/DeliveryStatus/List/PopoverRow';
import {
  StyledTableHeaderActions,
  StyledTableHeaderButton,
} from '@src/components/Desktop/Containers/TableHeaderActions';
import { ModalEnum } from '@src/components/Desktop/Pages/Private/DeliveryStatus/List/List';
import { DrawerButton } from '@src/components/Elements/DrawerButton';
import { FilterForm } from '../FilterForm';
import { MaxWidthText } from '@src/components/Desktop/Containers/MaxWidthText';
import Moment from '@src/global/Moment';

interface TableProps extends AntdTableProps<any> {
  itemsPerPage: number;
  onItemClick?: (event: React.MouseEvent, row: DeliveryStatusNode, rowIndex: number) => void;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  setShowPopover: ({ id }: { id: string }) => void;
  showPopover: { id: string };
  toggleModal: (modalType: ModalEnum) => void;
}

export const Table: FC<TableProps> = ({
  itemsPerPage,
  setItemsPerPage,
  onItemClick,
  setShowPopover,
  toggleModal,
  showPopover,
  ...props
}) => {
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const { userHasPermission } = useContext(MeContext);
  const [queryVariables, setQueryVariables] = useState<any>({
    first: itemsPerPage,
    activeOnly: true,
  });
  const [page, setPage] = useState<number>(1);
  const [getDeliveryStatuses, { loading, error, data, refetch }] = useLazyQuery<{
    deliveryStatuses: DeliveryStatusNodeConnection;
  }>(DeliveryStatus.getDeliveryStatuses, {
    variables: queryVariables,
    errorPolicy: 'none',
    fetchPolicy: 'network-only',
    onError: () => {
      setToast({
        type: TOAST_TYPES.ERROR,
        message: t('desktop.pages.deliveryStatus.list.error.message'),
        description: t('desktop.pages.deliveryStatus.list.error.description'),
      });
    },
  });
  const { deliveryStatuses: { edges } = { edges: [] as any } } = data || {};
  const piles = edges.map(({ node }) => node);

  useEffect(() => {
    setQueryVariables((currentQueryVariables) => ({ ...currentQueryVariables, first: itemsPerPage }));
    setPage(1);
  }, [itemsPerPage, setQueryVariables, setPage]);

  useEffect(() => {
    getDeliveryStatuses();
  }, [queryVariables, getDeliveryStatuses]);

  useEffect(() => {
    const el = document;
    const ev = new Event('resize', { bubbles: true, cancelable: false });
    el.dispatchEvent(ev);
  });

  const onCellClicked = (e: React.MouseEvent, record: DeliveryStatusNode, index: number | undefined) => {
    if (onItemClick && index !== undefined) {
      onItemClick(e, record, index);
    }
  };

  const columns: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.deliveryStatus.list.columns.number'),
      dataIndex: 'number',
      onCell: (record, index) => ({
        onClick: (e) => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.deliveryStatus.list.columns.customer'),
      dataIndex: ['customer', 'name'],
      onCell: (record, index) => ({
        onClick: (e) => onCellClicked(e, record, index),
      }),
      render: (text) => <MaxWidthText maxWidth='20em'>{text}</MaxWidthText>,
    },
    {
      title: t('desktop.pages.deliveryStatus.list.columns.shortDescription'),
      dataIndex: 'shortDescription',
      onCell: (record, index) => ({
        onClick: (e) => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.deliveryStatus.list.columns.dateBegin'),
      dataIndex: 'dateBegin',
      render: (text) => <Moment format='DD.MM.YYYY'>{text}</Moment>,
      onCell: (record, index) => ({
        onClick: (e) => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.deliveryStatus.list.columns.dateEnd'),
      dataIndex: 'dateEnd',
      render: (text) => <Moment format='DD.MM.YYYY'>{text}</Moment>,
      onCell: (record, index) => ({
        onClick: (e) => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.deliveryStatus.list.columns.amount'),
      dataIndex: 'amount',
      onCell: (record, index) => ({
        onClick: (e) => onCellClicked(e, record, index),
      }),
    },
    {
      title: t('desktop.pages.deliveryStatus.list.columns.unit'),
      dataIndex: 'unit',
      onCell: (record, index) => ({
        onClick: (e) => onCellClicked(e, record, index),
      }),
      render: (unit) => t(`general.deliveryStatusUnit.${unit}`),
    },
    {
      title: t('desktop.pages.deliveryStatus.list.columns.type'),
      dataIndex: 'type',
      render: (type) => t(`general.deliveryStatusType.${type}`),
      onCell: (record, index) => ({
        onClick: (e) => onCellClicked(e, record, index),
      }),
    },
    {
      title: () => <IonIcon mode='md' icon={ellipsisVertical} size='small' color='medium' />,
      dataIndex: 'action',
      fixed: 'right',
      width: 50,
      render: (text, record: any) => (
        <Popover
          placement='leftTop'
          content={<PopoverRow toggleModal={toggleModal} deliveryStatus={record} />}
          trigger='click'
          overlayClassName={'popover-actions'}
        >
          <span>
            <IonIcon mode='md' icon={ellipsisVertical} size='small' />
          </span>
        </Popover>
      ),
    },
  ];

  return (
    <>
      {error && !data && (
        <Alert
          message={t('desktop.pages.deliveryStatus.list.error.message')}
          description={t('desktop.pages.deliveryStatus.list.error.description')}
          type='error'
          showIcon
        />
      )}

      {loading && (
        <StyledIonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </StyledIonRow>
      )}
      <StyledTableHeaderActions>
        {userHasPermission(Permissions.CAN_ADD_DELIVERY_STATUS) && (
          <StyledTableHeaderButton size='large' type='link' onClick={() => toggleModal(ModalEnum.ADD)}>
            <IonIcon icon={add} />
            {t('desktop.pages.deliveryStatus.list.createDeliveryStatus')}
          </StyledTableHeaderButton>
        )}
        <DrawerButton icon={funnel} buttonText={t('desktop.pages.general.filter.button')}>
          <FilterForm setQueryVariables={setQueryVariables} setPage={setPage} itemsPerPage={itemsPerPage} />
        </DrawerButton>
      </StyledTableHeaderActions>

      {data &&
        (edges?.length > 0 ? (
          <>
            <StyledTable {...props} columns={columns} dataSource={piles} rowKey='id' pagination={false} />

            {!error && (
              <Pagination
                loading={loading}
                pageInfo={data.deliveryStatuses && data.deliveryStatuses.pageInfo}
                page={page}
                setPage={setPage}
                queryVariables={queryVariables}
                setQueryVariables={setQueryVariables}
              />
            )}

            {error && (
              // uses callback wrapper because of this issue ->
              // https://github.com/apollographql/apollo-client/issues/1291#issuecomment-367911441
              // eslint-disable-next-line
              <StyledButton onClick={() => refetch()}>{t('general.buttonRefetch')}</StyledButton>
            )}
          </>
        ) : (
          <IonTitle className='ion-text-center'>
            {t('desktop.pages.deliveryStatus.list.noDeliveryStatusAvailable')}
          </IonTitle>
        ))}
    </>
  );
};
