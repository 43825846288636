import { IonIcon } from '@ionic/react';
import { Drawer } from 'antd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTableHeaderButton } from '../../Desktop/Containers/TableHeaderActions';

interface DrawerButtonProps {
  buttonText: string;
  icon: string;
}

export const DrawerButton: FC<DrawerButtonProps> = ({ buttonText, icon, children }) => {
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <>
      <StyledTableHeaderButton size='large' type='link' onClick={toggleDrawer}>
        <IonIcon icon={icon} />
        {buttonText}
      </StyledTableHeaderButton>

      <Drawer
        title={t('desktop.pages.piles.filter.headline')}
        placement='right'
        closable={false}
        onClose={toggleDrawer}
        open={showDrawer}
      >
        {children}
      </Drawer>
    </>
  );
};
