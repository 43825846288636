import React, { FC, SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonAlert } from '@ionic/react';
import { useApolloClient } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';

import { InventoryNode, OrderNode, PileNode } from '@src/generated/schema';
import { Inventory } from '@src/graphql/Inventory';
import { InventoriesContext, OfflineHandlerContext } from '@src/components/Mobile/Providers';

import { Stacks } from './Stacks';
import { Form } from './Form';
import { StyledButton, StyledIonButtonRow, StyledIonCol, StyledIonGrid, StyledIonText } from './InventoryForm.styles';
import { useMutation } from 'react-apollo';
import { Modal } from '@src/components/Elements/Modal';
import { ModalLevel } from '@src/components/Elements/Modal/Modal';

export interface StackState {
  stackHeight: number;
  stackNumber: number;
  stackWidth: number;
  woodLength: number;
}

export interface InventoryState {
  amount: number;
  count: number;
  isBroached: boolean;
  rest: number | undefined;
}

export interface ValidationError {
  field: string;
  message: string;
}

type InventoryMutationType = 'create' | 'update' | 'delete';

export interface InventoryFormProps {
  alertModalLevel?: ModalLevel;
  createEnabled?: boolean;
  deleteEnabled?: boolean;
  editEnabled?: boolean;
  inventory?: InventoryNode;
  locationData?: {
    accuracy: number | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
  };
  offlineHandling?: boolean;
  onSubmit?: (inventory?: InventoryNode) => void;
  order?: OrderNode;
  pile?: PileNode;
}

export const InventoryForm: FC<InventoryFormProps> = ({
  alertModalLevel,
  order,
  pile,
  deleteEnabled,
  editEnabled,
  createEnabled,
  onSubmit,
  inventory,
  offlineHandling,
  locationData,
}) => {
  // general data
  const client = useApolloClient();
  const { t } = useTranslation();

  // data for form
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>();
  const [nextKey, setNextKey] = useState<number>(5);
  const { addJob } = useContext(OfflineHandlerContext);
  const [showCountAlert, setShowCountAlert] = useState<boolean>(false);

  // data to fill and submit form
  const { addInventory } = useContext(InventoriesContext);
  const [stacks, setStacks] = useState<StackState[]>([]);
  const [inventoryState, setInventoryState] = useState<InventoryState>({
    amount: inventory?.amount || 0,
    count: inventory?.count || 0,
    isBroached: inventory?.isBroached || false,
    rest: inventory?.rest || 0,
  });

  const [updateInventory] = useMutation(Inventory.updateInventory);
  const [deleteInventory] = useMutation(Inventory.deleteInventory);
  const [createInventory] = useMutation(Inventory.createInventory);

  const prevInventory = useRef<InventoryState>(inventoryState);

  /**
   * Counts the number of decimals behind `.`
   *
   * 2.00 -> 2
   * 1.2 -> 1
   * 3 -> 0
   */
  const countDecimals = (value: number): number => {
    if (!value || Math.floor(value) === value) return 0;

    return value.toString().split('.')[1]?.length || 0;
  };

  /**
   * Handles adding a stack entry.
   */
  const handleAddStack = () => {
    setStacks((prev: StackState[]) => [
      ...prev,
      { stackNumber: nextKey, woodLength: pile?.logLength || 0, stackWidth: 2.3, stackHeight: 0 },
    ]);
  };

  /**
   * Handles an update to the stack.
   */
  const handleUpdateStacks = (stackKey: number, column: string, target: HTMLInputElement) => {
    // Input value is a number, as the event targets are number-inputs.
    let value = target.value as unknown as number;

    // Breite muss mindestens 2m sein, darf aber nicht größer als 2,90m sein
    if (column === 'width' && value > 2.9) {
      value = 2.9;
    }

    if (column === 'width' && value < 2) {
      value = 2;
    }

    if ((column === 'length' || 'width') && countDecimals(value) > 2) {
      value = Math.floor(value * 100) / 100;
    }

    setStacks((prevStacks) =>
      prevStacks.map((stack) => {
        if (stack.stackNumber === stackKey) {
          stack[column] = value;
        }

        return stack;
      }),
    );
  };

  /**
   * Handles delete from stack.
   */
  const handleDeleteStack = (stackKey: number) => {
    setStacks((prevStacks) => prevStacks.filter((stack) => stack.stackNumber !== stackKey));
  };

  useEffect(() => {
    if (stacks.length === 0) {
      setNextKey(1);
    } else {
      setNextKey(Number(stacks.slice(-1)[0].stackNumber) + 1);
    }
  }, [stacks, setNextKey]);

  /**
   * Handles an update in the inventory form.
   */
  const handleUpdateInventory = (event: CustomEvent, field: string) => {
    if (field === 'broached') {
      setInventoryState((previousInventory) => ({ ...previousInventory, isBroached: event.detail.checked }));
    } else {
      setInventoryState((previousInventory) => ({
        ...previousInventory,
        [field]: (event.target as HTMLInputElement)?.value,
      }));
    }
  };

  /**
   * Validates the form.
   */
  const validateForm = useCallback(
    (update): boolean => {
      const errors: ValidationError[] = [];

      // Höhe muss mindestens einmal größer 0 eingegeben sein und kann nicht höher als 2,90 sein
      if (inventoryState.amount === 0) {
        errors.push({ field: 'amount', message: t('containers.inventoryForm.form.errors.amount') });
      }

      // Wenn er den Polter als geräumt angibt darf er keine Restmenge angeben
      if (inventoryState.isBroached && Number(inventoryState.rest)) {
        errors.push({
          field: 'isBroachedContext',
          message: t('containers.inventoryForm.form.errors.broachedOrRest'),
        });
      }

      // Wenn er den Polter als nicht geräumt angibt muss er eine Restmenge angeben
      if (!inventoryState.isBroached && !Number(inventoryState.rest)) {
        errors.push({
          field: 'isBroachedContext',
          message: t('containers.inventoryForm.form.errors.broachedOrRest'),
        });
      }

      if (inventoryState.rest === undefined || inventoryState.rest < 0) {
        errors.push({ field: 'rest', message: t('containers.inventoryForm.form.errors.rest') });
      }

      if (!order || !order?.id) {
        errors.push({ field: 'nonField', message: t('containers.inventoryForm.form.errors.oops') });
      } else if (!update && (!pile || !pile?.id)) {
        errors.push({ field: 'nonField', message: t('containers.inventoryForm.form.errors.oops') });
      }

      setValidationErrors(errors);

      return !errors.length;
    },
    [inventoryState, t, order, pile],
  );

  /**
   * Handles the form submit of deleting the inventory.
   */
  const handleDeleteSubmit = () => {
    if (deleteEnabled && inventory) {
      performMutation('delete', { inventoryId: inventory.id }, inventory.id);
    }

    if (onSubmit) {
      onSubmit();
    }
  };

  /**
   * Catches the submit for the create and update event and checks if these actions are valid.
   */
  const handleUpdateOrCreateSubmit = (event?: SyntheticEvent) => {
    if (inventory && editEnabled) {
      performUpdateAndCreate(true, event);
    } else if (createEnabled) {
      performUpdateAndCreate(false, event);
    }
  };

  /**
   * Performs the mutation in the end. This function considers if the Offline module should be used or not.
   */
  const performMutation = async (type: InventoryMutationType, variables, refId: string | null = null) => {
    if (offlineHandling) {
      if (type === 'create') {
        addJob({
          variables,
          refId,
          mutation: Inventory.createInventory,
          refetchOrders: true,
        });
      } else {
        throw Error('This was not configured yet.');
      }
    } else {
      switch (type) {
        case 'create':
          await createInventory({ variables });
          break;
        case 'delete':
          await deleteInventory({ variables });
          break;
        case 'update':
          await updateInventory({ variables });
          break;
        default:
          throw Error('This was not configured yet.');
      }
    }
  };

  /**
   * Performs everything around updating and creating an inventory. It also checks if everything is valid before that.
   */
  const performUpdateAndCreate = async (update: boolean, event?: SyntheticEvent) => {
    event?.preventDefault();

    const isValid: boolean = validateForm(update);

    if (!isValid || isSubmitting) {
      return;
    }

    // handled in validation
    if (!order?.id || !order) {
      return;
    }

    if (Number(inventoryState.count) === 0 && !!event) {
      setShowCountAlert(true);

      return;
    }

    setIsSubmitting(true);

    const nextInventoryId = `temp-${uuidv4()}`;

    const nextInventory: Omit<InventoryNode, 'id'> = {
      ...inventoryState,
      amount: Number(inventoryState.amount),
      count: inventoryState.count ? Number(inventoryState.count) : 0,
      rest: Number(inventoryState.rest),
      time: new Date().toISOString(),
      // if you are having problems with the type: if the schema was generated, the default type is:
      // inventoryDetails?: Maybe<Array<Maybe<InventoryDetailNode>>>,
      // the type is correct but the type InventoryNode above is wrong. Instead MutationCreateInventoryArgs should
      // be used because the id for inventoryDetails is not required. For now, the line above was changed to:
      //
      // inventoryDetails?: Maybe<Array<Maybe<InventoryDetailType>>>,
      //
      // if you generate the code again, you might have to add that line again
      // Also you might have problems with the createdAt Type. The Schema generation makes it required, which it is
      // really not. To fix that simply put.
      //
      // createdAt?: Scalars['DateTime'],
      //
      inventoryDetails: stacks.map((stack) => ({
        stackHeight: stack.stackHeight,
        stackNumber: stack.stackNumber,
        stackWidth: stack.stackWidth,
        woodLength: stack.woodLength,
      })),
      accuracy: locationData?.accuracy,
      longitude: locationData?.longitude,
      latitude: locationData?.latitude,
    };

    addInventory(order?.id, { ...nextInventory, pile, id: nextInventoryId });

    try {
      if (update) {
        await performMutation('update', { ...nextInventory, inventoryId: inventory?.id }, inventory?.id);
      } else {
        // handled in validation
        if (!pile?.id) {
          return;
        }

        await performMutation('create', { ...nextInventory, order: order.id, pile: pile.id }, nextInventoryId);
      }
    } catch (e) {
      setValidationErrors([{ field: 'nonField', message: (e as Error).message }]);
      setIsSubmitting(false);

      return;
    }

    if (onSubmit) {
      onSubmit({ ...nextInventory, pile, id: nextInventoryId });
    }
  };

  useEffect(() => {
    setInventoryState((previousInventory) => {
      const amount = parseFloat(
        stacks.reduce((prev, cur) => prev + (cur.woodLength || 0) * cur.stackWidth * cur.stackHeight, 0).toFixed(3),
      );

      return { ...previousInventory, amount };
    });
  }, [stacks]);

  useEffect(() => {
    if (
      prevInventory.current.count !== inventoryState.count ||
      prevInventory.current.isBroached !== inventoryState.isBroached ||
      // eslint-disable-next-line
      prevInventory.current.rest !== inventoryState.rest ||
      prevInventory.current.amount !== inventoryState.amount
    ) {
      validateForm(!!inventory);
    }
    prevInventory.current = inventoryState;
  }, [inventoryState, validateForm, inventory]);

  useEffect(() => {
    /**
     * Returns a default entry for a stack.
     */
    const getDefaultStack: (stackNumber: number) => StackState = (stackNumber) => ({
      stackNumber,
      woodLength: pile?.logLength || 0,
      stackWidth: 2.3,
      stackHeight: 0,
    });

    if (inventory && inventory.inventoryDetails) {
      // if an inventory is given, we need to fill in missing entries in order to keep the logic:
      // stackNumber of an entry === index + 1
      const result: StackState[] = inventory.inventoryDetails.reduce((accumulator: StackState[], value) => {
        // the entry that was provided in the inventoryDetails
        const newEntry: StackState = {
          woodLength: value?.woodLength || 0,
          stackNumber: value?.stackNumber || 0,
          stackWidth: value?.stackWidth || 0,
          stackHeight: value?.stackHeight || 0,
        };
        // the stackNumber that the accumulator should have next
        const requiredStackNumber = accumulator.length + 1;

        // if the stackNumber of the entry is correct, simply add it to the accumulator
        if (newEntry.stackNumber === requiredStackNumber) {
          accumulator.push(newEntry);

          return accumulator;
        }

        // if the stackNumber is incorrect, there are entries missing in between
        // add an entry for each missing entry in between each number
        [...Array(newEntry.stackNumber - requiredStackNumber)].forEach((entry, i) => {
          accumulator.push(getDefaultStack(requiredStackNumber + i));
        });

        // after each inBetween entry, add the original one
        accumulator.push(newEntry);

        return accumulator;
      }, []);

      setStacks(result);
    } else {
      setStacks([getDefaultStack(1), getDefaultStack(2), getDefaultStack(3), getDefaultStack(4)]);
    }
  }, [client, setStacks, setNextKey, inventory, pile]);

  return (
    <StyledIonGrid>
      <Stacks
        handleAddStack={handleAddStack}
        handleDeleteStack={handleDeleteStack}
        handleUpdateStacks={handleUpdateStacks}
        stacks={stacks}
        inputDisabled={!editEnabled && !createEnabled}
      />

      <Form
        showSave={!!editEnabled || !!createEnabled}
        showDelete={!!deleteEnabled}
        handleSaveSubmit={handleUpdateOrCreateSubmit}
        handleDeleteSubmit={handleDeleteSubmit}
        handleUpdateInventory={handleUpdateInventory}
        inventory={inventoryState}
        isSubmitting={isSubmitting}
        validationErrors={validationErrors}
      />
      {alertModalLevel === undefined ? (
        <IonAlert
          buttons={[
            {
              text: t('containers.inventoryForm.form.message.cancel'),
              role: 'cancel',
            },
            {
              text: t('containers.inventoryForm.form.message.confirm'),
              handler: () => handleUpdateOrCreateSubmit(undefined),
            },
          ]}
          isOpen={showCountAlert}
          message={t('containers.inventoryForm.form.message.body')}
          onDidDismiss={() => setShowCountAlert(false)}
        />
      ) : (
        <Modal
          open={showCountAlert}
          level={alertModalLevel}
          tabComponents={[
            {
              tabName: '',
              component: (
                <>
                  <StyledIonCol size='3' push='5'>
                    <StyledIonText color='dark'>{t('containers.inventoryForm.form.message.body')}</StyledIonText>
                  </StyledIonCol>
                  <StyledIonButtonRow>
                    <StyledIonCol size='6'>
                      <StyledButton
                        type='button'
                        onClick={() => setShowCountAlert(false)}
                        color='primary'
                        fill='solid'
                        expand='block'
                        disabled={isSubmitting}
                      >
                        {t('containers.inventoryForm.form.message.cancel')}
                      </StyledButton>
                    </StyledIonCol>
                    <StyledIonCol size='6'>
                      <StyledButton
                        type='button'
                        onClick={() => handleUpdateOrCreateSubmit(undefined)}
                        color='primary'
                        fill='solid'
                        expand='block'
                        disabled={isSubmitting}
                      >
                        {t('containers.inventoryForm.form.message.confirm')}
                      </StyledButton>
                    </StyledIonCol>
                  </StyledIonButtonRow>
                </>
              ),
            },
          ]}
          onCancel={() => setShowCountAlert(false)}
          title={t('containers.inventoryForm.form.message.title')}
        />
      )}
    </StyledIonGrid>
  );
};
