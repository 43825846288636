import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { closePopups } from '@src/helpers/DomManipulations';
import { PileNode } from '@src/generated/schema';
import { TestIds } from '@src/global';
import { DefaultMarkerHeight } from '@src/global/Map';
import { Container, Wrapper } from '@src/components/Elements';
import { Map } from '@src/components/Containers/Map';
import { PageHeadline } from '@src/components/Elements/PageHeadline';
import { ItemsPerPagePicker } from '@src/components/Desktop/Containers/ItemsPerPagePicker';

import { Table } from './Table';
import { StyledCustomMapControls, StyledList, StyledPilesSearch } from './List.styles';
import { Modal } from '@src/components/Elements/Modal';
import { Add as OrderAdd } from '@src/components/Desktop/Pages/Private/Orders/Edit/Add';
import { AddPileToOrder } from '@src/components/Desktop/Pages/Private/Orders/Edit/AddPileToOrder';
import { InventoryDetails } from '@src/components/Desktop/Pages/Private/Piles/InventoryDetails';
import { OrderDetails } from '@src/components/Desktop/Pages/Private/Piles/OrderDetails/OrderDetails';
import { Add as PileAdd } from '@src/components/Desktop/Pages/Private/Piles/Edit/Add';
import { Alter } from '@src/components/Desktop/Pages/Private/Piles/Edit/Alter';
import { ImportPiles } from '../ImportPiles';
import { DeletePiles } from '@src/components/Desktop/Pages/Private/Piles/Edit/Delete';
import { ExportPiles } from '@src/components/Desktop/Pages/Private/Piles/ExportPiles';

export enum ModalEnum {
  CREATE = 'CREATE',
  CHANGE = 'change',
  DELETE = 'delete',
  TRANSFER_TO_ORDER = 'transferToOrder',
  INVENTORY_DETAILS = 'inventoryDetails',
  ORDER_DETAILS = 'orderDetails',
  MAP = 'map',
  ADD_TO_EXISTING = 'addToExisting',
  IMPORT_PILES = 'importPiles',
  EXPORT_PILES = 'exportPiles',
}

const modalTitles = {
  [ModalEnum.CHANGE]: 'desktop.pages.piles.change.title',
  [ModalEnum.DELETE]: 'desktop.pages.piles.delete.title',
  [ModalEnum.MAP]: 'desktop.pages.piles.list.mapModal.title',
  [ModalEnum.TRANSFER_TO_ORDER]: 'desktop.pages.orders.add.title',
  [ModalEnum.CREATE]: 'desktop.pages.piles.create.title',
  [ModalEnum.INVENTORY_DETAILS]: 'desktop.pages.piles.inventoryDetails.title',
  [ModalEnum.ORDER_DETAILS]: 'desktop.pages.piles.orderDetails.title',
  [ModalEnum.ADD_TO_EXISTING]: 'desktop.pages.piles.addToExisting.title',
  [ModalEnum.IMPORT_PILES]: 'desktop.pages.piles.importPiles.title',
  [ModalEnum.EXPORT_PILES]: 'desktop.pages.piles.exportPiles.title',
};

export type ModalData = PileNode[] | PileNode;
export type ModalDataState = ModalData | undefined;

export const List: FC = () => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<PileNode[]>([]);
  const [showModal, setShowModal] = useState<string>('');
  const [showPopover, setShowPopover] = useState({ id: '' });
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [modalData, setModalData] = useState<ModalDataState>(undefined);

  useEffect(() => {
    if (showModal) setShowPopover({ id: '' });
  }, [showModal]);

  /**
   * Closes any modal and cleans up.
   */
  const closeModal = () => {
    setShowModal('');
    setModalData(undefined);
  };

  /**
   * Opens any Modal
   */
  const openModal = (modalType: ModalEnum, data?: ModalData) => {
    closePopups();
    setShowModal(modalType);
    setModalData(data);
  };

  const extractSinglePileNodeFromState: (state: ModalDataState) => PileNode | undefined = (state) => {
    if (!state) return undefined;

    return Array.isArray(state) ? state[0] : state;
  };

  return (
    <div data-testid={TestIds.pages.piles.list.identifier}>
      {showModal === ModalEnum.MAP && (
        <Modal
          onCancel={closeModal}
          title={t(modalTitles[ModalEnum.MAP])}
          open={!!showModal}
          tabComponents={[
            {
              component: (
                <>
                  <StyledCustomMapControls className='leaflet-control-custom leaflet-bar leaflet-control'>
                    <StyledPilesSearch piles={selectedRows} onSearch={setModalData} />
                  </StyledCustomMapControls>
                  <Map markers={modalData} markerHeight={DefaultMarkerHeight} />
                </>
              ),
              tabName: t(modalTitles[ModalEnum.MAP]),
              isMap: true,
            },
          ]}
        />
      )}
      {showModal === ModalEnum.TRANSFER_TO_ORDER && (
        <Modal
          onCancel={closeModal}
          title={t(modalTitles[ModalEnum.TRANSFER_TO_ORDER])}
          open={!!showModal}
          tabComponents={[
            {
              component: <OrderAdd piles={selectedRows} closeModal={closeModal} />,
              tabName: t(modalTitles[ModalEnum.TRANSFER_TO_ORDER]),
            },
          ]}
        />
      )}
      {showModal === ModalEnum.DELETE && (
        <Modal
          onCancel={closeModal}
          title={t(modalTitles[ModalEnum.DELETE])}
          open={!!showModal}
          tabComponents={[
            {
              component: <DeletePiles piles={selectedRows} closeModal={closeModal} />,
              tabName: t(modalTitles[ModalEnum.DELETE]),
            },
          ]}
        />
      )}
      {showModal === ModalEnum.CREATE && (
        <Modal
          onCancel={closeModal}
          title={t(modalTitles[ModalEnum.CREATE])}
          open={!!showModal}
          tabComponents={[
            {
              component: <PileAdd closeModal={closeModal} />,
              tabName: t(modalTitles[ModalEnum.CREATE]),
            },
          ]}
        />
      )}
      {showModal === ModalEnum.CHANGE && (
        <Modal
          onCancel={closeModal}
          title={`${t(modalTitles[ModalEnum.CHANGE])}: ${extractSinglePileNodeFromState(modalData)?.number}`}
          open={!!showModal}
          tabComponents={[
            {
              component: <Alter toggleModal={closeModal} pile={extractSinglePileNodeFromState(modalData)} />,
              tabName: t(modalTitles[ModalEnum.CHANGE]),
            },
          ]}
        />
      )}
      {showModal === ModalEnum.INVENTORY_DETAILS && (
        <Modal
          onCancel={closeModal}
          title={`${t('desktop.pages.piles.details.title')}: ${extractSinglePileNodeFromState(modalData)?.number}`}
          open={!!showModal}
          tabComponents={[
            {
              component: <InventoryDetails modalData={modalData} />,
              tabName: t(modalTitles[ModalEnum.INVENTORY_DETAILS]),
            },
            {
              component: <OrderDetails modalData={modalData} />,
              tabName: t(modalTitles[ModalEnum.ORDER_DETAILS]),
            },
            {
              component: (
                <>
                  <Map markers={modalData} markerHeight={DefaultMarkerHeight} />
                </>
              ),
              tabName: t(modalTitles[ModalEnum.MAP]),
              isMap: true,
            },
          ]}
        />
      )}
      {showModal === ModalEnum.ADD_TO_EXISTING && (
        <Modal
          onCancel={closeModal}
          title={t(modalTitles[ModalEnum.ADD_TO_EXISTING])}
          open={!!showModal}
          tabComponents={[
            {
              component: <AddPileToOrder piles={selectedRows} closeModal={closeModal} />,
              tabName: t(modalTitles[ModalEnum.ADD_TO_EXISTING]),
            },
          ]}
        />
      )}
      {showModal === ModalEnum.IMPORT_PILES && (
        <Modal
          onCancel={closeModal}
          title={t(modalTitles[ModalEnum.IMPORT_PILES])}
          open={!!showModal}
          tabComponents={[
            {
              component: <ImportPiles closeModal={closeModal} />,
              tabName: t(modalTitles[ModalEnum.IMPORT_PILES]),
            },
          ]}
        />
      )}
      {showModal === ModalEnum.EXPORT_PILES && (
        <Modal
          onCancel={closeModal}
          title={t(modalTitles[ModalEnum.EXPORT_PILES])}
          open={!!showModal}
          tabComponents={[
            {
              component: <ExportPiles piles={selectedRows} closeModal={closeModal} />,
              tabName: t(modalTitles[ModalEnum.EXPORT_PILES]),
            },
          ]}
        />
      )}
      <Container>
        <Wrapper>
          <PageHeadline>{t('desktop.pages.piles.headline')}</PageHeadline>
          <ItemsPerPagePicker setItemsPerPage={setItemsPerPage} itemsPerPage={itemsPerPage} />
          <StyledList>
            <Table
              onItemClick={(e, row) => openModal(ModalEnum.INVENTORY_DETAILS, row)}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setShowPopover={setShowPopover}
              showPopover={showPopover}
              openModal={openModal}
            />
          </StyledList>
        </Wrapper>
      </Container>
    </div>
  );
};
