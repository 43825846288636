import styled from 'styled-components';
import { MaxSizeProps } from '../../Pages/Private/Orders/List/Table/Table';

export const MaxWidthText = styled.span<MaxSizeProps>`
  overflow: hidden;
  display: block;
  max-width: ${(props) => props.maxWidth || '11em'};
  text-overflow: ellipsis;
  white-space: nowrap;
`;
