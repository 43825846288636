import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { closePopups } from '@src/helpers/DomManipulations';
import { Container, Wrapper } from '@src/components/Elements';
import { PageHeadline } from '@src/components/Elements/PageHeadline';
import { ItemsPerPagePicker } from '@src/components/Desktop/Containers/ItemsPerPagePicker';
import { Table } from './Table';
import { StyledList } from './List.styles';
import { CompanyNode } from '@src/generated/schema';
import { Modal } from '@src/components/Elements/Modal';
import { TabComponentsType } from '@src/components/Elements/Modal/Modal';
import { Add as CompanyAdd } from '../Edit/Add';
import { Show as CompanyShow } from '../Show';
import { Alter as CompanyAlter } from '../Edit/Alter';

export enum ModalEnum {
  ADD = 'add',
  SHOW = 'show',
  CHANGE = 'change',
}

const modalTitles = {
  [ModalEnum.ADD]: 'desktop.pages.companies.add.title',
  [ModalEnum.SHOW]: 'desktop.pages.companies.show.title',
  [ModalEnum.CHANGE]: 'desktop.pages.companies.edit.title',
};

export const List: FC = () => {
  const { t } = useTranslation();

  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [showModal, setShowModal] = useState<string>('');
  const [showPopover, setShowPopover] = useState({ id: '' });
  const [clickedCompany, setClickedCompany] = useState<CompanyNode | undefined>(undefined);

  /**
   * Closes any modal and cleans up.
   */
  const closeModal = () => {
    setShowModal('');
    setClickedCompany(undefined);
  };

  /**
   * Opens any Modal
   */
  const openModal = (modalType?: ModalEnum, companyNode?: any) => {
    closePopups();
    setShowModal(modalType || '');
    setClickedCompany(companyNode);
  };
  /**
   * Closes any modal and cleans up.
   */

  const toggleModal: () => void = (modalType?: ModalEnum, companyNode?: any) => {
    if (showModal) {
      closeModal();
    } else {
      openModal(modalType, companyNode);
    }
  };

  const makeModal = (tabComponents: TabComponentsType) => (
    <Modal
      title={clickedCompany ? `${t(modalTitles[showModal])}: ${clickedCompany?.matchcode}` : t(modalTitles[showModal])}
      open={!!showModal}
      onCancel={toggleModal}
      tabComponents={tabComponents}
    />
  );

  return (
    <div>
      {showModal === ModalEnum.ADD &&
        makeModal([{ component: <CompanyAdd closeModal={closeModal} />, tabName: t(modalTitles.add) }])}
      {showModal === ModalEnum.CHANGE &&
        makeModal([
          {
            component: <CompanyAlter toggleModal={toggleModal} currentCompanyId={clickedCompany?.id} />,
            tabName: t(modalTitles.change),
          },
        ])}
      {showModal === ModalEnum.SHOW &&
        makeModal([
          {
            component: <CompanyShow company={clickedCompany} />,
            tabName: t(modalTitles.show),
          },
        ])}

      <Container>
        <Wrapper>
          <PageHeadline>{t('desktop.pages.companies.headline')}</PageHeadline>
          <ItemsPerPagePicker setItemsPerPage={setItemsPerPage} itemsPerPage={itemsPerPage} />
          <StyledList>
            <Table
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setShowPopover={setShowPopover}
              showPopover={showPopover}
              onItemClick={(e, row) => openModal(ModalEnum.SHOW, row)}
              toggleModal={toggleModal}
            />
          </StyledList>
        </Wrapper>
      </Container>
    </div>
  );
};
