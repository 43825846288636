import { createContext } from 'react';
import { OperationVariables } from '@apollo/react-common/lib/types/types';

import { RelayItemsPerPageMobile } from '@src/global';
import { OrderStatus } from '@src/graphql/Order/Types';

export const QUEUE_STORAGE_KEY = 'logistik-queue';

interface MutationJob {
  id: string;
  mutation?: any;
  refetchOrders?: boolean;
  refId?: string;
  variables?: OperationVariables;
}

export interface OrderVariables {
  activeOnly?: boolean;
  first?: number;
  status?: OrderStatus;
}

interface FileUploadJob {
  file: File;
  fileName?: string;
  id: string;
}

type InternetConnectionType = 'wifi' | 'cellular' | 'none' | 'unknown';

type Job = MutationJob | FileUploadJob;

interface OfflineHandlerContextProps {
  addJob: (args: Omit<Job, 'id'>) => void;
  internetConnectionType: InternetConnectionType;
  jobs: Job[];
  lastOrdersFetch: Date | null;
  refetchOrders: (variables?: OrderVariables) => void;
  removeJob: (id: string) => void;
}

export const OfflineHandlerContext = createContext<OfflineHandlerContextProps>({
  addJob: () => undefined,
  internetConnectionType: 'unknown',
  jobs: [],
  lastOrdersFetch: null,
  refetchOrders: () => undefined,
  removeJob: () => undefined,
});

// these are some predefined filters for the orders that are used in the app. It can be be useful to use these
// to access the cache
export type OrderCacheFilterType = 'all' | 'activeOnly' | 'finished';
export const ORDERS_QUERY_FINISHED_ONLY = { activeOnly: undefined, status: OrderStatus.Finished };
export const ORDERS_QUERY_ACTIVE_ONLY = { activeOnly: true, status: undefined };
export const ORDERS_QUERY_ALL = { activeOnly: undefined, status: undefined };
export const ORDERS_QUERY_FALLBACK = { ...ORDERS_QUERY_ACTIVE_ONLY, first: RelayItemsPerPageMobile };
