import React, { FC } from 'react';
import { DatePickerProps as AntDatePickerProps } from 'formik-antd/lib/date-picker';
import { FormItem, FormItemProps } from 'formik-antd';
import { StyledDatePicker } from '@src/components/Desktop/Containers/DatePicker/DatePicker.styles';
import moment, { Moment } from 'moment';

type FormItemPropsClean = Pick<FormItemProps, 'label' | 'name'>;
type DatePickerProps = Pick<AntDatePickerProps, 'onChange'> &
  FormItemPropsClean & {
    format?: string;
    required?: boolean;
    defaultValue?: moment.Moment;
    showTime?: boolean | { defaultValue: moment.Moment } | undefined;
  };

export const DatePicker: FC<DatePickerProps> = ({
  label,
  name,
  required,
  format,
  defaultValue,
  showTime,
  ...props
}) => (
  <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label={label} name={name} required={required} hasFeedback>
    <StyledDatePicker
      format={format || 'DD.MM.YYYY'}
      name={name}
      defaultValue={defaultValue}
      showTime={showTime}
      {...(props as any)}
    />
  </FormItem>
);
