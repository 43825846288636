import React from 'react';
import moment from 'moment';

/**
 * Component to display date in a given format.
 */
const Moment = ({ format, children }) => {
  const formattedDate = moment(children).format(format);

  return <span>{formattedDate}</span>;
};

export default Moment;
