import React, { FC, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { SubmitButton } from 'formik-antd';
import { Button } from 'antd';
import { map, create, addCircleOutline } from 'ionicons/icons';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import {
  IonFooter,
  IonToolbar,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonAlert,
  IonGrid,
  IonRow,
  IonCol,
  IonListHeader,
  IonButton,
} from '@ionic/react';

import { Inventory } from '@src/graphql/Inventory';
import { Order } from '@src/graphql/Order';
import {
  InventoryNodeConnection,
  MutationEditOrderArgs,
  OrderNode,
  Scalars,
  TimeNodeConnection,
} from '@src/generated/schema';
import { FilesystemRoutes, TestIds, FILETYPE_PDF, MIMETYPE_PDF, FileType, Routes } from '@src/global';
import { isTemporaryId } from '@src/helpers/Logic';
import {
  FileContext,
  OfflineHandlerContext,
  InventoriesContext,
  LocationContext,
} from '@src/components/Mobile/Providers';
import { Page } from '@src/components/Mobile/Sections/Page';
import { InventoryList } from '@src/components/Mobile/Pages/Private/Orders/Detail/InventoryList';
import { FileUpload } from '@src/components/Mobile/Containers/FileUpload';

import {
  StyledTitle,
  StyledUploadContainer,
  StyledNote,
  StyledInfoText,
  StyledSpinner,
  StyledIonCol,
  StyledTextArea,
  StyledModal,
  StyledAddIonButtonDiv,
} from './Detail.styles';
import { InventorySummary } from '@src/components/Mobile/Pages/Private/Orders/Detail/InventorySummary';
import { ToastContext } from '@src/components/Providers';
import { OrderStatus } from '@src/graphql/Order/Types';
import { InventoryWagonList } from '@src/components/Mobile/Pages/Private/Orders/Detail/InventoryWagonList';

const { TRANSPORT_MEDIA, DELIVERY_NOTES } = FilesystemRoutes;

const statusOrder = {
  [OrderStatus.Allocated]: OrderStatus.Commenced,
  [OrderStatus.Transmitted]: OrderStatus.Commenced,
  [OrderStatus.Commenced]: OrderStatus.Loaded,
  [OrderStatus.Loaded]: OrderStatus.Finished,
};

interface AdditionalOrderMutationInfo {
  accuracy?: number | undefined;
  latitude?: number | undefined;
  longitude?: number | undefined;
  repeatOnFinished?: Scalars['Boolean'];
}

export interface FormValues {
  noteDriver?: string | undefined | null;
}

export const Detail: FC = () => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();
  const client = useApolloClient();
  const { addJob, removeJob } = useContext(OfflineHandlerContext);
  const { setIonToast } = useContext(ToastContext);
  const { openPdf, checkFileExists } = useContext(FileContext);
  const { orderInventories, deleteInventory } = useContext(InventoriesContext);
  const location = useContext(LocationContext);
  const [order, setOrder] = useState<OrderNode | null | undefined>();
  const [showPdfButton, setShowPdfButton] = useState<boolean>(false);
  const [showStatusUpdateAlert, setShowStatusUpdateAlert] = useState<boolean>(false);
  const [showFinishedAlert, setShowFinishedAlert] = useState<boolean>(false);

  const [isEditNoteOpen, setIsEditNoteOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Performs the mutation in the end. This function considers if the Offline module should be used or not.
   */
  const performMutation = (variables, refId: string | null = null) => {
    addJob({
      variables,
      refId,
      mutation: Order.editOrder,
      refetchOrders: true,
    });

    setIsSubmitting(false);
    setIsEditNoteOpen(false);
  };

  /**
   * Performs everything around updating and creating an inventory. It also checks if everything is valid before that.
   */
  const performUpdateNoteDriver = (values: FormValues) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    // handled in validation
    if (!order?.id || !order) {
      setIsSubmitting(false);

      return;
    }

    order.noteDriver = values.noteDriver;

    const variables: MutationEditOrderArgs = {
      amount: order.amount,
      customer: order.customer.id,
      deliveryStatus: order?.deliveryStatus?.id,
      driver: order?.driver?.id,
      note: order.note,
      order: order.id,
      planDate: order.planDate,
      repeat: order.repeat,
      repeatEndDate: order.repeatEndDate,
      supplier: order?.supplier?.id,
      transportMedia: order?.transportMedia?.id,
      vehicle: order?.vehicle?.id,
      noteDriver: values.noteDriver,
    };

    try {
      performMutation({ ...variables }, order?.id);
    } catch (e) {
      setIsSubmitting(false);
      setIonToast({
        show: true,
        message: t('general.mutationError'),
      });
    }
  };

  const inventories = orderId ? orderInventories[orderId].filter((item) => !item.wagonNumber && item.isCalculated) : [];
  const wagonInventories = orderId ? orderInventories[orderId].filter((item) => !!item.wagonNumber) : [];
  const inventorySummary = orderId && order?.inventorySummary ? order?.inventorySummary : [];

  const nextStatus: OrderStatus | undefined = (order && order.status && statusOrder[order.status]) || undefined;

  const handleOpenPdf = () => {
    openPdf(TRANSPORT_MEDIA, `${orderId}${FILETYPE_PDF}`, MIMETYPE_PDF);
  };

  const { loading, data } = useQuery<{
    inventories: InventoryNodeConnection;
    order: any;
    times: TimeNodeConnection;
  }>(Order.getDriverOrdersDetail, {
    variables: { orderId: order && order.id },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data && data.order) {
      setOrder(data.order);
    }
  }, [data, setOrder]);

  function updateOrderStatus(additionalVariables?: AdditionalOrderMutationInfo): void {
    if (!orderId || !order || !nextStatus) return;

    const nextOrder: OrderNode = {
      ...order,
      ...additionalVariables,
      status: nextStatus,
    };

    setOrder(nextOrder);

    client.writeFragment({
      id: orderId,
      fragment: Order.DriverOrderNodeFragment,
      fragmentName: 'driverOrderNode',
      data: nextOrder,
    });

    let variables = {
      status: nextStatus,
      dispatchedAt: new Date().toISOString(),
      idList: [orderId],
      accuracy: location.accuracy,
      latitude: location.latitude,
      longitude: location.longitude,
    };

    if (additionalVariables) {
      variables = { ...additionalVariables, ...variables };
    }

    addJob({
      variables,
      mutation: Order.setOrderStatus,
      refetchOrders: true,
    });
  }

  function handleFinishedStatusChange(repeat: boolean): void {
    /**
     * Handles the finished status change after the user has selected if the wants to repeat the order or not.
     *
     * @param repeat [bool]: user has selected that he wants to repeat the order.
     */
    updateOrderStatus({ repeatOnFinished: repeat });
  }

  function handleStatusChange(): void {
    // in case the order can repeat and the next status is finished, we want to ask the user if he wants to
    // repeat first
    if (nextStatus === OrderStatus.Finished && order && order.repeat) {
      setShowFinishedAlert(true);
    } else {
      updateOrderStatus();
    }
  }

  // TODO: Maybe only visually mark inventory items as deleted until deletion was confirmed by the server.
  function handleInventoryDelete(id: string): void {
    if (!orderId || !order) return;

    deleteInventory(orderId, id);
    removeJob(id);

    if (!isTemporaryId(id)) {
      addJob({
        mutation: Inventory.deleteInventory,
        refetchOrders: true,
        variables: {
          inventoryId: id,
        },
      });
    }
  }

  useEffect(() => {
    if (orderId) {
      setOrder(
        client.readFragment({
          id: orderId,
          fragment: Order.DriverOrderNodeFragment,
          fragmentName: 'driverOrderNode',
        }),
      );
    }
  }, [orderId, client, setOrder]);

  useEffect(() => {
    async function checkForFile() {
      try {
        const fileExists = await checkFileExists(TRANSPORT_MEDIA, `${orderId}${FILETYPE_PDF}`);

        setShowPdfButton(fileExists);
      } catch (error) {
        setShowPdfButton(false);
      }
    }

    checkForFile();
  }, [orderId, checkFileExists]);

  return (
    <>
      <Page
        renderFooter={
          <IonFooter>
            <IonToolbar>
              <IonButton slot='start' fill='clear' routerLink={`${Routes.ORDERS}/${orderId}${Routes.PILES}`}>
                <IonIcon icon={map} />
              </IonButton>

              <IonAlert
                isOpen={showStatusUpdateAlert}
                onDidDismiss={() => setShowStatusUpdateAlert(false)}
                header={t('mobile.pages.orders.detail.statusChangeButton.alert.header')}
                subHeader={t('mobile.pages.orders.detail.statusChangeButton.alert.subheader', {
                  newStatus: t(`general.status.${nextStatus}`),
                })}
                buttons={[
                  {
                    text: t('mobile.pages.orders.detail.statusChangeButton.alert.cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                  },
                  {
                    text: t('mobile.pages.orders.detail.statusChangeButton.alert.confirm'),
                    handler: handleStatusChange,
                  },
                ]}
              />

              <IonAlert
                isOpen={showFinishedAlert}
                onDidDismiss={() => setShowFinishedAlert(false)}
                header={t('mobile.pages.orders.detail.finishedRepeatButton.alert.header')}
                subHeader={t('mobile.pages.orders.detail.finishedRepeatButton.alert.subheader')}
                buttons={[
                  {
                    text: t('mobile.pages.orders.detail.finishedRepeatButton.alert.decline'),
                    handler: () => handleFinishedStatusChange(false),
                  },
                  {
                    text: t('mobile.pages.orders.detail.finishedRepeatButton.alert.accept'),
                    handler: () => handleFinishedStatusChange(true),
                  },
                ]}
              />

              {order && nextStatus && (
                <IonButton slot='primary' fill='clear' onClick={() => setShowStatusUpdateAlert(true)}>
                  {t(`mobile.pages.orders.detail.statusChangeButton.${nextStatus}`)}
                </IonButton>
              )}
            </IonToolbar>
          </IonFooter>
        }
      >
        <StyledModal
          title={t('desktop.pages.orders.show.modal.title.editNoteDriver')}
          open={isEditNoteOpen}
          onCancel={() => setIsEditNoteOpen(false)}
          tabComponents={[
            {
              tabName: '',
              component: (
                <>
                  <Formik initialValues={{ noteDriver: order?.noteDriver }} onSubmit={performUpdateNoteDriver}>
                    <Form>
                      <StyledTextArea
                        label={t('mobile.pages.orders.detail.noteDriver')}
                        type='text'
                        name='noteDriver'
                      />
                      <StyledIonCol size='6'>
                        <Button onClick={() => setIsEditNoteOpen(false)} disabled={isSubmitting}>
                          {t('general.cancel')}
                        </Button>
                      </StyledIonCol>
                      <StyledIonCol size='6'>
                        <SubmitButton type='primary' disabled={isSubmitting}>
                          {t('general.confirm')}
                        </SubmitButton>
                      </StyledIonCol>
                    </Form>
                  </Formik>
                </>
              ),
            },
          ]}
        />

        <div data-testid={TestIds.pages.orders.detail.identifier}>
          <IonList>
            <IonListHeader mode='md'>
              {t('mobile.pages.orders.detail.orderNumber')}

              {order?.number}
            </IonListHeader>

            <IonItem>
              <IonLabel>{t('mobile.pages.orders.detail.supplier')}</IonLabel>

              <StyledNote slot='end' color='primary'>
                {order?.supplier?.name}
              </StyledNote>
            </IonItem>

            <IonItem>
              <IonLabel>{t('mobile.pages.orders.detail.customer')}</IonLabel>

              <StyledNote slot='end' color='primary'>
                {order?.customer?.name}
              </StyledNote>
            </IonItem>

            <IonItem>
              <IonLabel>{t('mobile.pages.orders.detail.transportMedia')}</IonLabel>

              {showPdfButton ? (
                <IonButton onClick={handleOpenPdf}>{t('mobile.pages.orders.detail.viewPdf')}</IonButton>
              ) : (
                <StyledNote slot='end' color='primary'>
                  {t('mobile.pages.orders.detail.noPdf')}
                </StyledNote>
              )}
            </IonItem>

            <IonItem>
              <IonLabel>{t('mobile.pages.orders.detail.status')}</IonLabel>

              <StyledNote slot='end' color='primary'>
                {t(`desktop.pages.orders.show.values.status.${order?.status}`)}
              </StyledNote>
            </IonItem>

            <IonItem>
              <IonLabel>{t('mobile.pages.orders.detail.note')}</IonLabel>

              <StyledNote slot='end' color='primary'>
                {order?.note}
              </StyledNote>
            </IonItem>

            <IonItem lines='none'>
              <IonLabel>{`${t('mobile.pages.orders.detail.noteDriver')}:`}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel className='ion-text-wrap'>{order?.noteDriver}</IonLabel>
              <StyledNote>
                <IonButton fill='clear' onClick={() => setIsEditNoteOpen(true)}>
                  <IonIcon icon={create} />
                </IonButton>
              </StyledNote>
            </IonItem>
          </IonList>

          <StyledUploadContainer>
            <StyledTitle>{t('mobile.pages.orders.detail.uploads')}</StyledTitle>

            <IonGrid>
              <IonRow>
                <IonCol>
                  <FileUpload folderName={DELIVERY_NOTES} fileName={`${orderId}-${FileType.CUSTOMER}`} />
                </IonCol>

                <IonCol>
                  <FileUpload folderName={DELIVERY_NOTES} fileName={`${orderId}-${FileType.SUPPLIER}`} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </StyledUploadContainer>

          <StyledTitle>{t('mobile.pages.orders.detail.inventorySummary')}</StyledTitle>

          {loading && <StyledSpinner />}

          {!!inventorySummary?.length && <InventorySummary inventorySummary={inventorySummary} />}

          {!loading && !inventorySummary?.length && (
            <StyledInfoText>{t('mobile.pages.orders.detail.noInventoryData')}</StyledInfoText>
          )}

          <StyledTitle>{t('mobile.pages.orders.detail.inventory')}</StyledTitle>

          {!!inventories?.length && (
            <InventoryList inventories={inventories} onInventoryDelete={handleInventoryDelete} />
          )}

          {!inventories?.length && <StyledInfoText>{t('mobile.pages.orders.detail.noInventoryData')}</StyledInfoText>}

          <StyledTitle>{t('mobile.pages.orders.detail.wagonInventory')}</StyledTitle>

          {!!wagonInventories?.length && (
            <InventoryWagonList inventories={wagonInventories} onInventoryDelete={handleInventoryDelete} />
          )}

          {!wagonInventories?.length && (
            <StyledInfoText>{t('mobile.pages.orders.detail.noInventoryData')}</StyledInfoText>
          )}

          <StyledAddIonButtonDiv>
            <IonButton fill={'clear'} routerLink={`${Routes.ORDERS}/${orderId}${Routes.ADD_WAGON}`}>
              <IonIcon slot='icon-only' icon={addCircleOutline} />
            </IonButton>
          </StyledAddIonButtonDiv>
        </div>
      </Page>
    </>
  );
};
