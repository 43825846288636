import React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import * as Yup from 'yup';

import { Formik, FormikValues } from 'formik';
import { Switch } from '@src/components/Desktop/Containers/Switch/Switch';
import { isFieldRequired } from '@src/helpers';
import { SubmitButton } from 'formik-antd';
import { FormikHelpers } from 'formik/dist/types';
import { ButtonCol } from '@src/components/Mobile/Containers/MapWrapper/FilterDrawer/FilterDrawer.styles';
import { useTranslation } from 'react-i18next';
import { Input } from '@src/components/Desktop/Containers/Input';
import { FormWrapper } from '@src/components/Mobile/Pages/Private/Piles/FilterDrawer/FilterDrawer.styles';
import { MobileLocationSelect } from '@src/components/Mobile/Pages/Private/Piles/FilterDrawer/LocationSelect/LocationSelect';
import { MobileSortSelect } from '@src/components/Mobile/Pages/Private/Piles/FilterDrawer/SortSelect';
import { MobileCategorySelect } from '@src/components/Mobile/Pages/Private/Piles/FilterDrawer/CategorySelect/CategorySelect';
import { MobileSupplierSelect } from '@src/components/Mobile/Pages/Private/Piles/FilterDrawer/SupplierSelect';
import { MobileQualityTypeSelect } from '@src/components/Mobile/Pages/Private/Piles/FilterDrawer/QualityTypeSelect';
import { MobileTimberSelect } from '@src/components/Mobile/Pages/Private/Piles/FilterDrawer/TimberSelect';
import { MobilePileTypeSelect } from '@src/components/Mobile/Pages/Private/Piles/FilterDrawer/PileTypeSelect';
import { MobileClientSelect } from '@src/components/Mobile/Pages/Private/Piles/FilterDrawer/ClientSelect';
import { MobileUsageSortSelect } from '@src/components/Mobile/Pages/Private/Piles/FilterDrawer/UsageSortSelect';
import { PileNode } from '@src/generated/schema';

export interface DrawerProps {
  initialValue?: PileFilterForm;
  onCloseDrawer: () => void;
  onSubmit: (state: PileFilterForm, filtersAreActive: boolean) => void;
  piles: PileNode[];
  visible?: boolean;
}

export interface PileFilterForm {
  category?: string[];
  client?: string[];
  company?: string[];
  includeBroached: boolean;
  location?: string[];
  pileNumber?: string;
  plotNumber?: string;
  qualityType?: string[];
  sort?: string[];
  timber?: string[];
  type?: string[];
  usageSort?: string[];
}

export const FilterDrawer: React.FC<DrawerProps> = ({ visible, initialValue, onCloseDrawer, piles, onSubmit }) => {
  const { t } = useTranslation();

  const initialValues: PileFilterForm = {
    category: initialValue?.category || [],
    client: initialValue?.client || [],
    company: initialValue?.company || [],
    includeBroached: initialValue?.includeBroached === undefined ? false : initialValue?.includeBroached,
    location: initialValue?.location || [],
    pileNumber: initialValue?.pileNumber || undefined,
    plotNumber: initialValue?.plotNumber || undefined,
    qualityType: initialValue?.qualityType || [],
    sort: initialValue?.sort || [],
    timber: initialValue?.timber || [],
    type: initialValue?.type || [],
    usageSort: initialValue?.usageSort || [],
  };

  const validationSchema = Yup.object().shape({
    category: Yup.array(Yup.string()),
    client: Yup.array(Yup.string()),
    includeBroached: Yup.boolean().default(false),
    location: Yup.array(Yup.string()),
    pileNumber: Yup.string(),
    company: Yup.array(Yup.string()),
    plotNumber: Yup.string(),
    qualityType: Yup.array(Yup.string()),
    sort: Yup.array(Yup.string()),
    timber: Yup.array(Yup.string()),
    type: Yup.array(Yup.string()),
    usageSort: Yup.array(Yup.string()),
  });

  const getAllFieldKeys = () => Object.keys(initialValues);

  /**
   * Checks if any of the values of the form have changed aka if the form has any filters active.
   */
  const valuesHaveChanged = (values: FormikValues) =>
    getAllFieldKeys().filter(
      (key) => (Array.isArray(values[key]) && values[key].length > 0) || (!Array.isArray(values[key]) && !!values[key]),
    ).length > 0;

  /**
   * Called when the form is submitted.
   */
  const onFormSubmit = (values: PileFilterForm, actions: FormikHelpers<PileFilterForm>) => {
    onSubmit(values, valuesHaveChanged(values));
    actions.setSubmitting(false);
  };

  /**
   * Resets the form to its initial values.
   */
  const resetForm = (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    getAllFieldKeys().forEach((key) => {
      if (key === 'includeBroached') {
        setFieldValue('includeBroached', false);
      } else {
        setFieldValue(key, undefined);
      }
    });
  };

  return (
    <Drawer
      title={t('mobile.containers.mapWrapper.filterDrawer.title')}
      open={visible}
      placement='bottom'
      onClose={onCloseDrawer}
      getContainer={false}
      height='80vh'
    >
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ isValid, handleSubmit, values, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Switch
                label={t('mobile.containers.mapWrapper.filterDrawer.label.includeBroached')}
                required={isFieldRequired('includeBroached', validationSchema)}
                name='includeBroached'
              />

              <Input
                label={t('general.pile.number')}
                type='text'
                name='pileNumber'
                required={isFieldRequired('pileNumber', validationSchema)}
              />

              <Input
                label={t('general.pile.plotNumber')}
                type='text'
                name='plotNumber'
                required={isFieldRequired('plotNumber', validationSchema)}
              />

              <MobileCategorySelect
                piles={piles}
                label={t('general.pile.category')}
                name='category'
                prefilledValueMulti={values?.category}
              />

              <MobileClientSelect
                piles={piles}
                label={t('general.pile.client')}
                name='client'
                prefilledValueMulti={values?.client}
              />

              <MobileLocationSelect
                name='location'
                piles={piles}
                prefilledValueMulti={values?.location}
                label={t('general.pile.location')}
              />

              <MobileSupplierSelect
                label={t('general.supplier')}
                name='company'
                piles={piles}
                prefilledValueMulti={values?.company}
              />

              <MobileSortSelect
                piles={piles}
                name='sort'
                prefilledValueMulti={values?.sort}
                label={t('general.pile.sort')}
              />

              <MobileQualityTypeSelect
                piles={piles}
                label={t('general.pile.qualityType.title')}
                name='qualityType'
                prefilledValueMulti={values?.qualityType}
              />

              <MobileTimberSelect
                piles={piles}
                name='timber'
                label={t('general.pile.timber')}
                prefilledValueMulti={values?.timber}
              />

              <MobilePileTypeSelect
                piles={piles}
                label={t('general.pile.type.title')}
                name='type'
                prefilledValueMulti={values?.type}
              />

              <MobileUsageSortSelect
                piles={piles}
                label={t('general.pile.usageSort.title')}
                name='usageSort'
                prefilledValueMulti={values?.usageSort}
              />

              <ButtonCol md={24}>
                <Button
                  disabled={!valuesHaveChanged(values) || isSubmitting}
                  onClick={() => resetForm(setFieldValue)}
                  icon={<SyncOutlined />}
                >
                  {t('mobile.containers.mapWrapper.filterDrawer.resetButton')}
                </Button>
              </ButtonCol>

              <ButtonCol md={24}>
                <SubmitButton className='ion-activatable' type='primary' disabled={!isValid || isSubmitting}>
                  {t('mobile.pages.piles.filterDrawer.submitButton')}
                </SubmitButton>
              </ButtonCol>
            </form>
          )}
        </Formik>
      </FormWrapper>
    </Drawer>
  );
};
