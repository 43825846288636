import React, { FC, Dispatch, SetStateAction } from 'react';
import { Select } from 'antd';
import { StyledSelect } from './ItemsPerPagePicker.styles';

const { Option } = Select;

interface ItemsPerPagePickerProps {
  itemsPerPage: number;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
}

export const ItemsPerPagePicker: FC<ItemsPerPagePickerProps> = ({ itemsPerPage, setItemsPerPage }) => (
  <StyledSelect onChange={setItemsPerPage} value={itemsPerPage}>
    <Option value={10}>10</Option>
    <Option value={20}>20</Option>
    <Option value={50}>50</Option>
    <Option value={100}>100</Option>
    <Option value={250}>250</Option>
    <Option value={500}>500</Option>
  </StyledSelect>
);
