import React, { FC } from 'react';
import { IonApp, isPlatform } from '@ionic/react';
import { Mobile } from './Mobile';

import { Desktop } from './Desktop';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { AppStyles } from './App.styles';
import { ResizeProvider, ToastProvider } from '@src/components/Providers';
import { ConfigProvider } from 'antd';
export interface AppProps {
  theme: DefaultTheme;
}

export const App: FC<AppProps> = ({ theme }) => (
  <ConfigProvider theme={{ token: { colorPrimary: theme.global.brandColor, fontFamily: 'PT Sans' } }}>
    <ThemeProvider theme={theme}>
      <AppStyles />
      <ResizeProvider>
        <ToastProvider>
          <IonApp>{isPlatform('hybrid') ? <Mobile /> : <Desktop />}</IonApp>
        </ToastProvider>
      </ResizeProvider>
    </ThemeProvider>
  </ConfigProvider>
);
