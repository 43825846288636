import React, { useState, useContext } from 'react';
import { Drawer, Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledInput } from './DownloadDrawer.styles';
import { OfflineMapTilesHandlerContext } from '../../../Providers';
import { StyledButton } from '@src/components/Mobile/Containers/MapWrapper/PileDetailDrawer/PileDetailDrawer.styles';

export interface DownloadDrawerProps {
  onCloseDrawer: () => void;
  visible: boolean;
}

export const DownloadDrawer: React.FC<DownloadDrawerProps> = ({ visible, onCloseDrawer }) => {
  const { t } = useTranslation();
  const { estimatedSize, downloadTiles } = useContext(OfflineMapTilesHandlerContext);
  const [name, setName] = useState('');

  const handleDownload = () => {
    downloadTiles(name);
    setName('');
    onCloseDrawer();
  };

  const handleInputChange = (event) => {
    setName(event.target.value);
  };

  return (
    <Drawer
      title={t('containers.map.mapDownload')}
      open={visible}
      placement='bottom'
      onClose={onCloseDrawer}
      getContainer={false}
      height='58vh'
    >
      <>
        <Typography>{t('containers.map.downloadName')}</Typography>
        <StyledInput
          type='text'
          name='name'
          placeholder={t('containers.map.nameDownload')}
          spellCheck='false'
          onChange={handleInputChange}
          value={name}
        />
        <Descriptions>
          <Descriptions.Item>{t('containers.map.downloadDescription')}</Descriptions.Item>
        </Descriptions>

        <StyledButton
          type='button'
          color='primary'
          fill='solid'
          expand='block'
          onClick={handleDownload}
          disabled={!name || name.length < 2}
        >
          {`Download ~${estimatedSize}`}
        </StyledButton>
      </>
    </Drawer>
  );
};
