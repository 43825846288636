import React, { FC } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { StyledButton, StyledChevronIcon, StyledUserIcon } from './UserDropdown.styles';
import { arrowDown, personCircle } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { IonRouterLink } from '@ionic/react';
import { Routes } from '@src/global';

export const UserDropdown: FC = () => {
  const { t } = useTranslation();
  const menuItems: MenuProps['items'] = [
    {
      key: 0,
      label: <IonRouterLink href={Routes.LOGOUT}>{t('desktop.elements.header.userDropdown.logout')}</IonRouterLink>,
    },
    {
      key: 1,
      label: (
        <IonRouterLink href={Routes.PASSWORD}>{t('desktop.elements.header.userDropdown.changePassword')}</IonRouterLink>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items: menuItems }} trigger={['click']}>
      <StyledButton>
        <StyledUserIcon className='icon' slot='start' icon={personCircle} />
        <StyledChevronIcon className='icon' icon={arrowDown} mode='ios' color='black' />
      </StyledButton>
    </Dropdown>
  );
};
