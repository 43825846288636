import React, { FC, useContext, useEffect, useState } from 'react';
import { Wrapper } from '@src/components/Elements';
import { useTranslation } from 'react-i18next';
import { StyledButton, StyledPilesDetailTable } from './OrderDetails.styles';
import { ModalDataState } from '@src/components/Desktop/Pages/Private/Piles/List/List';
import { OrderNodeConnection, PileNode } from '@src/generated/schema';
import { useLazyQuery } from '@apollo/react-hooks';
import { IonCol, IonRow, IonSpinner } from '@ionic/react';
import { Alert } from 'antd';
import { Tag, TagType } from '@src/components/Elements/Tag';
import { Order } from '@src/graphql/Order';
import { TOAST_TYPES, ToastContext } from '@src/components/Providers';
import { Pagination } from '@src/components/Desktop/Elements';
import { ColumnProps } from 'antd/lib/table/Column';
import { OrderStatus } from '@src/graphql/Order/Types';
import Moment from '@src/global/Moment';

export interface ShowProps {
  modalData: ModalDataState;
}

export const OrderDetails: FC<ShowProps> = ({ modalData }) => {
  const { t } = useTranslation();
  const pile: PileNode | undefined = Array.isArray(modalData) ? modalData[0] : modalData;
  const { setToast } = useContext(ToastContext);

  const [queryVariables, setQueryVariables] = useState<any>({
    first: 10,
  });
  const [page, setPage] = useState<number>(1);
  const [getPaginatedOrders, { loading, error, data, refetch }] = useLazyQuery<{ orders: OrderNodeConnection }>(
    Order.getPaginatedOrders,
    {
      variables: {
        usesPiles: pile && [pile.id],
        orderByActiveStatus: true,
        ...queryVariables,
      },
      fetchPolicy: 'network-only',
      onError: () => {
        setToast({
          type: TOAST_TYPES.ERROR,
          message: t('desktop.pages.orders.list.error.message'),
          description: t('desktop.pages.orders.list.error.description'),
        });
      },
    },
  );

  useEffect(() => {
    getPaginatedOrders();
  }, [queryVariables, getPaginatedOrders]);

  const { orders: { edges } = { edges: [] as any } } = data || {};
  const orders = edges.map(({ node }) => node);

  const columnsOrders: Array<ColumnProps<any>> = [
    {
      title: t('desktop.pages.piles.orderDetails.orders.columns.number'),
      dataIndex: 'number',
    },
    {
      title: t('desktop.pages.piles.orderDetails.orders.columns.planDate'),
      dataIndex: 'planDate',
      render: (text) => (text ? <Moment format='DD.MM.YY'>{text}</Moment> : '-'),
    },
    {
      title: t('desktop.pages.piles.orderDetails.orders.columns.finishedAt'),
      dataIndex: 'finishedAt',
      render: (text) => (text ? <Moment format='DD.MM.YY'>{text}</Moment> : '-'),
    },
    {
      title: t('desktop.pages.piles.orderDetails.orders.columns.status'),
      dataIndex: 'status',
      render: (text) => (
        <Tag
          type={text === OrderStatus.Created ? TagType.PRIMARY : TagType.DEFAULT}
          key={text}
          value={text}
          text={t(`general.status.${text}`)}
        />
      ),
    },
    {
      title: t('desktop.pages.piles.orderDetails.orders.columns.driver'),
      dataIndex: 'driver',
      render: (driver) => (driver ? `${driver.firstName} ${driver.lastName}` : '-'),
    },
    {
      title: t('desktop.pages.piles.orderDetails.orders.columns.customer'),
      dataIndex: ['customer', 'name'],
    },
  ];

  return (
    <Wrapper>
      {loading && (
        <IonRow>
          <IonCol className='ion-text-center'>
            <IonSpinner name='crescent' />
          </IonCol>
        </IonRow>
      )}

      {error && (
        <Alert
          message={t('desktop.pages.orders.show.error.message')}
          description={t('desktop.pages.orders.show.error.description')}
          type='error'
          showIcon
        />
      )}

      {data && (
        <>
          <StyledPilesDetailTable
            title={() => t('desktop.pages.piles.orderDetails.orders.title')}
            columns={columnsOrders}
            dataSource={orders || []}
            rowKey='id'
            pagination={false}
          />

          {!error && (
            <Pagination
              loading={loading}
              pageInfo={data.orders && data.orders.pageInfo}
              page={page}
              setPage={setPage}
              queryVariables={queryVariables}
              setQueryVariables={setQueryVariables}
            />
          )}
          {error && (
            // uses callback wrapper because of this issue ->
            // https://github.com/apollographql/apollo-client/issues/1291#issuecomment-367911441
            // eslint-disable-next-line
            <StyledButton onClick={() => refetch()}>{t('general.buttonRefetch')}</StyledButton>
          )}
        </>
      )}
    </Wrapper>
  );
};
