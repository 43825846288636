import { createGlobalStyle } from 'styled-components';
import { BREAKPOINTS, THEME } from '@src/theme';
import { darken, lighten, parseToRgb } from 'polished';

export const AppStyles = createGlobalStyle`
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root {
  /** primary **/
  --ion-color-primary: ${THEME.global.brandColor};
  --ion-color-primary-rgb: ${parseToRgb(THEME.global.brandColor) as any};
  --ion-color-primary-contrast: ${THEME.color.white};
  --ion-color-primary-contrast-rgb: ${parseToRgb(THEME.global.color) as any};
  --ion-color-primary-shade: ${darken(0.2, THEME.global.brandColor)};
  --ion-color-primary-tint: ${lighten(0.1, THEME.global.brandColor)};
  
  /** general **/
  --ion-font-family: ${THEME.global.fontFamily};
  --ion-text-color: ${THEME.global.color};
  font-size: ${THEME.global.fontSize}px;

  @media (max-height: ${BREAKPOINTS.medium}px) {
    font-size: ${THEME.global.fontSize - 1}px;
  }
}

  p {
    margin-top: 0;
    font-weight: ${THEME.fontWeight.secondary.regular};
    color: ${THEME.color.tundora};
    font-size: 1.05rem;
    line-height: 1.5;
  }

  h1 {
    font-family: ${THEME.fontFamily.primary};
    font-weight: ${THEME.fontWeight.primary.bold};
    font-size: 1.6rem !important;
    margin-bottom: 1rem !important;
  }

  button, [type='button'], [type='reset'], [type='submit'] {
    appearance: initial !important;
  }
`;
