import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledButton,
  StyledForm,
  StyledIonButtonRow,
  StyledIonCol,
  StyledIonInput,
  StyledIonRow,
  StyledIonSelect,
  StyledIonText,
} from '@src/components/Elements/StyledInput';
import { ValidationError } from '@src/components/Containers/InventoryForm/InventoryForm';
import {
  AmountUnitChoice,
  LocationTypeChoice,
  OldSystemStatusChoice,
  PileTypeChoice,
  QualityTypeChoice,
  UsageSortChoice,
} from '@src/generated/schema';
import {
  StyledIonColBroachedToggle,
  StyledIonTextError,
  StyledIonToggle,
} from '@src/components/Containers/InventoryForm/InventoryForm.styles';
import { IonDatetime, IonSelectOption } from '@ionic/react';
import { Drawer } from 'antd';
import { StyledPlotSelect } from '@src/components/Mobile/Pages/Private/Piles/Edit/PlotSelect';
import { FormValues } from '@src/components/Desktop/Pages/Private/Piles/Edit/Form';

interface FormProps {
  handleSaveSubmit: (e: any) => void;
  handleUpdatePile: (e: any, field: any) => void;
  pile: FormValues | undefined;
  showSave: boolean;
  validationErrors: ValidationError[] | undefined;
}

// eslint-disable-next-line complexity
export const EditForm: FC<FormProps> = ({ handleSaveSubmit, showSave, pile, handleUpdatePile, validationErrors }) => {
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const openDrawer = () => {
    setShowDrawer(true);
  };
  const onCloseDrawer = () => {
    setShowDrawer(false);
  };
  const addPlot = (e) => {
    e.target.value = parseInt(e.target.textContent, 0);
    handleUpdatePile(e, 'plotNumber');
    onCloseDrawer();
  };

  return (
    <>
      <StyledForm onSubmit={handleSaveSubmit}>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.number')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.number ? pile.number.toString() : null}
              onIonChange={(e) => handleUpdatePile(e, 'number')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.pileNumberFurnisher')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.pileNumberFurnisher ? pile.pileNumberFurnisher : null}
              onIonChange={(e) => handleUpdatePile(e, 'pileNumberFurnisher')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.markedAsBroached')}</StyledIonText>
          </StyledIonCol>
          <StyledIonColBroachedToggle>
            <StyledIonToggle
              value='false'
              checked={pile?.markedAsBroached}
              color='success'
              onIonChange={(e) => handleUpdatePile(e, 'markedAsBroached')}
            />
          </StyledIonColBroachedToggle>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.mustBeAccepted')}</StyledIonText>
          </StyledIonCol>
          <StyledIonColBroachedToggle>
            <StyledIonToggle
              value='false'
              checked={pile?.mustBeAccepted}
              color='success'
              onIonChange={(e) => handleUpdatePile(e, 'mustBeAccepted')}
            />
          </StyledIonColBroachedToggle>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.status.title')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonSelect
              interface='action-sheet'
              value={pile?.status ? pile.status : null}
              onIonChange={(e) => handleUpdatePile(e, 'status')}
            >
              {Object.values(OldSystemStatusChoice).map((status) => (
                <IonSelectOption key={status} value={status}>
                  {t(`general.pile.status.${status}`)}
                </IonSelectOption>
              ))}
            </StyledIonSelect>
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.type.title')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonSelect
              interface='action-sheet'
              value={pile?.type ? pile.type : null}
              onIonChange={(e) => handleUpdatePile(e, 'type')}
            >
              {Object.values(PileTypeChoice).map((type) => (
                <IonSelectOption key={type} value={type}>
                  {t(`general.pile.type.${type}`)}
                </IonSelectOption>
              ))}
            </StyledIonSelect>
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow onClick={openDrawer}>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.plotNumber')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{pile?.plotNumber}</StyledIonText>
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.locationType.title')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonSelect
              interface='action-sheet'
              value={pile?.locationType ? pile.locationType : null}
              onIonChange={(e) => handleUpdatePile(e, 'locationType')}
            >
              {Object.values(LocationTypeChoice).map((locationType) => (
                <IonSelectOption value={locationType} key={locationType}>
                  {t(`general.pile.locationType.${locationType}`)}
                </IonSelectOption>
              ))}
            </StyledIonSelect>
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.sort')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.sort ? pile.sort.toString() : null}
              onIonChange={(e) => handleUpdatePile(e, 'sort')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.location')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.location ? pile.location.toString() : null}
              onIonChange={(e) => handleUpdatePile(e, 'location')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.latitude')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.latitude ? pile.latitude.toString() : null}
              onIonChange={(e) => handleUpdatePile(e, 'latitude')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.longitude')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.longitude ? pile.longitude.toString() : null}
              onIonChange={(e) => handleUpdatePile(e, 'longitude')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.district')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.district ? pile.district : null}
              onIonChange={(e) => handleUpdatePile(e, 'district')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.locationPlan')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.locationPlan ? pile.locationPlan : null}
              onIonChange={(e) => handleUpdatePile(e, 'locationPlan')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.cbkNet')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.cbkNet ? pile.cbkNet.toString() : null}
              onIonChange={(e) => handleUpdatePile(e, 'cbkNet')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.cbkSolid')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.cbkSolid ? pile.cbkSolid.toString() : null}
              onIonChange={(e) => handleUpdatePile(e, 'cbkSolid')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.factorCbkNetCbkSolid')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.factorCbkNetCbkSolid ? pile.factorCbkNetCbkSolid : null}
              onIonChange={(e) => handleUpdatePile(e, 'factorCbkNetCbkSolid')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.logLength')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.logLength ? pile.logLength.toString() : null}
              onIonChange={(e) => handleUpdatePile(e, 'logLength')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.woodtype')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.woodtype ? pile.woodtype.toString() : null}
              onIonChange={(e) => handleUpdatePile(e, 'woodtype')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.woodtype2')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.woodtype2 ? pile.woodtype2 : null}
              onIonChange={(e) => handleUpdatePile(e, 'woodtype2')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.amountUnit.title')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonSelect
              interface='action-sheet'
              value={pile?.amountUnit ? pile.amountUnit : null}
              onIonChange={(e) => handleUpdatePile(e, 'amountUnit')}
            >
              {Object.values(AmountUnitChoice).map((amountUnit) => (
                <IonSelectOption key={amountUnit} value={amountUnit}>
                  {t(`general.pile.amountUnit.${amountUnit}`)}
                </IonSelectOption>
              ))}
            </StyledIonSelect>
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.usageSort.title')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonSelect
              interface='action-sheet'
              value={pile?.usageSort ? pile.usageSort : null}
              onIonChange={(e) => handleUpdatePile(e, 'usageSort')}
            >
              {Object.values(UsageSortChoice).map((usageSort) => (
                <IonSelectOption key={usageSort} value={usageSort}>
                  {usageSort}
                </IonSelectOption>
              ))}
            </StyledIonSelect>
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.qualityType.title')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonSelect
              interface='action-sheet'
              value={pile?.qualityType ? pile.qualityType : null}
              onIonChange={(e) => handleUpdatePile(e, 'qualityType')}
            >
              {Object.values(QualityTypeChoice).map((qualityType) => (
                <IonSelectOption key={qualityType} value={qualityType}>
                  {qualityType}
                </IonSelectOption>
              ))}
            </StyledIonSelect>
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.count')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='number'
              value={pile?.count ? pile.count : null}
              onIonChange={(e) => handleUpdatePile(e, 'count')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.category')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.category ? pile.category : null}
              onIonChange={(e) => handleUpdatePile(e, 'category')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.date')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <IonDatetime
              min={'2016'}
              max={'2099'}
              value={pile?.date ? pile.date.toISOString() : new Date().toISOString()}
              onIonChange={(e) => handleUpdatePile(e, 'date')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.timber')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.timber ? pile.timber : null}
              onIonChange={(e) => handleUpdatePile(e, 'timber')}
            />
          </StyledIonCol>
        </StyledIonRow>
        <StyledIonRow>
          <StyledIonCol size={'6'}>
            <StyledIonText color='dark'>{t('general.pile.note')}</StyledIonText>
          </StyledIonCol>
          <StyledIonCol size={'6'}>
            <StyledIonInput
              type='text'
              value={pile?.note ? pile.note : null}
              onIonChange={(e) => handleUpdatePile(e, 'note')}
            />
          </StyledIonCol>
        </StyledIonRow>

        {validationErrors && validationErrors.length > 0 && (
          <StyledIonButtonRow>
            <StyledIonCol size='12'>
              {validationErrors.map((item) => (
                <StyledIonTextError key={item.field} color='danger'>
                  {item.message}
                </StyledIonTextError>
              ))}
            </StyledIonCol>
          </StyledIonButtonRow>
        )}
        <StyledIonButtonRow>
          {showSave && (
            <StyledIonCol size={'12'}>
              <StyledButton type='submit' color='primary' fill='solid' expand='block'>
                {t('containers.inventoryForm.form.submit')}
              </StyledButton>
            </StyledIonCol>
          )}
        </StyledIonButtonRow>
        <Drawer
          title={'Plot hinzufügen'}
          open={showDrawer}
          placement='bottom'
          onClose={onCloseDrawer}
          getContainer={false}
          height='70vh'
        >
          <StyledPlotSelect handleOnClick={addPlot} prefilledValue={pile?.plotNumber} />
        </Drawer>
      </StyledForm>
    </>
  );
};
