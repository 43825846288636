import styled from 'styled-components';
import { Button } from 'antd';

export const StyledTableHeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledTableHeaderButton = styled(Button)`
  align-items: center;
  display: flex !important;
  gap: 0;
  color: ${({ theme }) => theme.global.brandColor};

  &:hover > * {
    color: ${({ theme }) => theme.color.cameo};
  }

  transition: none;

  > span {
    margin-left: 5px;
  }
`;

export const StyledResponsiveTableHeaderButton = styled(Button)`
  align-items: center;
  display: flex !important;
  gap: 0;
  color: ${({ theme }) => theme.global.brandColor};

  &:hover > * {
    color: ${({ theme }) => theme.color.cameo};
  }

  transition: none;

  > span {
    margin-left: 5px;
    display: none;
    @media ${({ theme }) => theme.media.large} {
      display: inline;
    }
  }
`;
